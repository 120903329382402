import React from "react";
import {Box, Container, Grid, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import Profile from "../../../assets/images/home/about/profile.webp";
import BackgroundGraphic from "../../../assets/svg/Ginkgo-2.svg";
import Sparkles from "../../../assets/svg/Sparkle-2-cropped.svg"
import {useNavigate} from "react-router-dom";
import {delay} from "../../../shared/functions/delay";
import StyledTabButton from "../../../shared/button/styled-tab-button/styled-tab-button";

interface HomeAboutProps {
  setNavigating: any;
}

const ProfileImage = () => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const mediumScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  return <Grid item xs={smallScreen ? 12 : 6} alignItems={"center"}
               justifyContent={"center"}>
    <Box sx={{
      display: 'flex',
      alignItems: mediumScreen ? 'start' : 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%'
    }}>
      <Box sx={{
        maxWidth: "500px",
        width: "40vw",
        minWidth: "250px",
        position: "relative"
      }}>
        <img src={Sparkles} alt={""} style={{
          maxWidth: "200px",
          width: "16vw",
          minWidth: "100px",
          position: "absolute",
          zIndex: 3,
          top: "clamp(-50px, -4vw, -25px)",
          right: "clamp(-50px, -4vw, -25px)"
        }}/>
        <img src={Profile} alt={""} style={{
          maxWidth: "500px",
          width: "40vw",
          minWidth: "250px",
          objectFit: "cover",
          objectPosition: "center center",
          borderTopLeftRadius: "50% 33%",
          borderTopRightRadius: "50% 33%",
          zIndex: 2
        }}/>
      </Box>
    </Box>
  </Grid>
}

const HomeAbout = (props: HomeAboutProps) => {
  const {setNavigating} = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const mediumScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const mediumOrSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  async function navigateToLink(to: string) {
    setNavigating(true)
    await delay(500);
    navigate(to)
    setNavigating(false)
  }

  return <Box sx={{
    width: "100vw", maxWidth: "100%",
    backgroundColor: "rgba(248,203,156)", overflow: "hidden", position: "relative"
  }}>
    <img alt={""} src={BackgroundGraphic}
         style={{
           position: "absolute", height: "100%", width: mediumOrSmallScreen ? "100%" : "80%", objectFit: "cover",
           left: 0,
           ...(smallScreen && {top: "20%"}),
           objectPosition: "center top",
           opacity: "20%"
         }}/>
    <Container maxWidth={smallScreen ? "md" : "xl"} sx={{py: smallScreen ? 8 : 12}}>
      <Box sx={{
        width: "100%", height: "100%",
        display: "flex", justifyContent: "center", alignItems: "center"
      }}>
        <Grid container columnSpacing={8} rowSpacing={smallScreen ? 4 : 12} justifyContent={"center"}>
          {smallScreen && <ProfileImage/>}
          <Grid item xs={smallScreen ? 12 : 6} alignItems={"center"} justifyContent={"center"}>
            <Box sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: "relative",
            }}>
              <Container maxWidth={"sm"}>
                <Stack sx={{width: "100%", zIndex: 1}} alignItems={"center"}
                       spacing={smallScreen ? 2 : 4}>
                  <Typography variant={smallScreen ? 'h5' : mediumScreen ? 'h4' : 'h3'} color={"primary"} paragraph
                              className={"header-font"} fontWeight={"bold"}
                              letterSpacing={6} sx={{zIndex: 2}}>
                    {`WHO IS SHE?`}
                  </Typography>
                  <Typography variant={"body1"} color={"primary"} paragraph
                              className={"body-font"}
                              textAlign={smallScreen ? "center" : "left"} sx={{zIndex: 2}}>
                    {`Well hello there! It's Marilyn here, your friendly neighbourhood emo turned wedding photographer.
                     I'm a tattooed mum who will arrive on the day most likely singing along to pop punk anthems.
                     During my down time, I'll be binge-watching anime with a cat curled in my lap.
                     A lover of gin & vegan food who is happy to talk to anyone about anything!`}</Typography>
                  <Typography variant={"body1"} color={"primary"} paragraph
                              className={"body-font"}
                              textAlign={smallScreen ? "center" : "left"} sx={{zIndex: 2}}>
                    {`I love meeting new folk and I adore seeing y'all besotted with each other.
                     I like to keep things as unstaged and natural as possible. All you need
                     are two people who can't keep their hands off of each other and you have a CRACKIN' photo.`}</Typography>
                  {mediumOrSmallScreen && <>
                    <Typography variant={smallScreen ? "h3" : mediumScreen ? "h3" : "h2"} color={"primary"}
                                sx={{
                                  mt: smallScreen ? "0 !important" : "",
                                  width: "100%",
                                  zIndex: 2,
                                }}
                                className={"scripted-font-alternate"}
                                textAlign={mediumOrSmallScreen ? "center" : "left"}>
                      {`Want to know more?`}
                    </Typography>
                  </>}
                  {!mediumOrSmallScreen &&
                      <Typography variant={"h2"} color={"primary"}
                                  sx={{
                                    width: "100%",
                                    zIndex: 2
                                  }}
                                  className={"scripted-font-alternate"}
                                  textAlign={"center"}>
                        {`Want to know more?`}
                      </Typography>}
                  <Box sx={{mt: -4}}>
                    <StyledTabButton onClick={() => navigateToLink("/about")} text={"Get to know me better"}
                                     sx={{zIndex: 2}}/>
                  </Box>
                </Stack>
              </Container>
            </Box>
          </Grid>
          {!smallScreen && <ProfileImage/>}
        </Grid>
      </Box>
    </Container>
  </Box>
}

export default HomeAbout;