import React from "react";
import {useMediaQuery, useTheme} from "@mui/material";
import NavBarMobile from "./mobile/nav-bar-mobile";
import NavBarDesktop from "./desktop/nav-bar-desktop";
import {NavBarProps} from "./nav-bar-props";

const NavBar = (props: NavBarProps) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return mobile ? <NavBarMobile {...props} /> : <NavBarDesktop {...props} />
}

export default NavBar;