import React from "react";
import {Button} from "@mui/material";

interface ImageButtonProps {
  imageURI: string;
  altText: string;
  size: number;
  round: boolean;
  onClick?: any;
  disabled?: boolean;
}

const ImageButton = (props: ImageButtonProps) => {
  const {imageURI, altText, size, round, onClick, disabled} = props;
  return <Button sx={{...(round && {borderRadius: "50%"})}} onClick={onClick} disabled={disabled}>
    <img src={imageURI} alt={altText} style={{
      maxWidth: size + "px",
      objectFit: "contain",
      objectPosition: "center center",
    }} />
  </Button>
}

export default ImageButton;