import React from "react";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import {Button, Divider} from "@mui/material";

interface StyledTabButtonProps {
  text: string;
  onClick: any;
  sx?: any;
}

const StyledTabButtonColoured = (props: StyledTabButtonProps) => {
  const {text, onClick, sx} = props;
  return <Button size={"large"} color={"error"}
                 variant={"outlined"}
                 endIcon={<KeyboardTabIcon sx={{ml: "4px", mr: "-4px"}}/>}
                 sx={{borderRadius: 0, mx: "auto", fontWeight: "bold", ...(sx)}}
                 onClick={onClick}>
    {text}
    <Divider
        flexItem
        sx={{
          my: "-7px",
          ml: "21px",
          backgroundColor: "inherit",
          borderColor: "inherit"
        }}
        orientation={"vertical"}/>
  </Button>
}

export default StyledTabButtonColoured;