import React from "react";
import {Box, Container, Grid, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import ImageButton from "../../../shared/image-button/image-button";
import RockMyWedding from "../../../assets/images/home/featured/rock-my-wedding-supplier.png";
import BelleBridal from "../../../assets/images/home/featured/as-seen-in-belle.png";
import SaveTheDate from "../../../assets/images/home/featured/featured+in+Save+the+Date+magazine+v1+030917.png";
import WeddingAssembly from "../../../assets/images/home/featured/wedding-assembly.png"
import WeddingChicks from "../../../assets/images/home/featured/2023-WC-Feature-Badge.png"
import UnconventionalWeddings from "../../../assets/images/home/featured/UW_featured_pink_RGB_AW.png"


interface FeaturedImageButtonProps {
  imageURI: string;
  altText: string;
  url: string;
}

const FeaturedImageButton = (props: FeaturedImageButtonProps) => {
  const {imageURI, altText, url} = props;
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const mediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  return <ImageButton imageURI={imageURI} altText={altText} size={smallScreen ? 75 : mediumScreen ? 100 : 150}
                      round={true}
                      onClick={() => window.open(url)}/>
}

const HomeFeatured = () => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const mediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  return <Box sx={{width: "100%", height: "100%"}}>
    <Container sx={{mx: "auto"}}>
      <Stack spacing={2}
             sx={{py: smallScreen ? 8 : 12}}>
        <Typography variant={smallScreen ? 'body1' : mediumScreen ? 'h4' : 'h3'} color={"white"}
                    letterSpacing={smallScreen ? 4 : 6}>YOU CAN FIND ME AT</Typography>
        <Grid container>
          <Grid item xs={smallScreen ? 4 : 2} sx={{py: smallScreen ? 2 : 6}}>
            <FeaturedImageButton imageURI={RockMyWedding} altText={""}
                                 url={"https://www.rockmywedding.co.uk/valentines-wedding-inspiration"}/>
          </Grid>
          <Grid item xs={smallScreen ? 4 : 2} sx={{py: smallScreen ? 2 : 6}}>
            <FeaturedImageButton imageURI={WeddingChicks} altText={""}
                                 url={"https://www.weddingchicks.com/real-weddings/modern-weddings/orange-crush-styled-wedding/"}/>
          </Grid>
          <Grid item xs={smallScreen ? 4 : 2} sx={{py: smallScreen ? 2 : 6}}>
            <FeaturedImageButton imageURI={BelleBridal} altText={""}
                                 url={"https://www.bellebridalmagazine.com/2023/05/05/ahead-of-the-curve-qa-with-influencer-amy-steed/"}/>
          </Grid>
          <Grid item xs={smallScreen ? 4 : 2} sx={{py: smallScreen ? 2 : 6}}>
            <FeaturedImageButton imageURI={SaveTheDate} altText={""}
                                 url={"https://www.savethedatemagazine.co.uk/blog/smashing-body-confidence"}/>
          </Grid>
          <Grid item xs={smallScreen ? 4 : 2} sx={{py: smallScreen ? 2 : 6}}>
            <FeaturedImageButton imageURI={WeddingAssembly} altText={""}
                                 url={"https://www.theweddingassembly.co.uk/directory/marilyn-denise"}/>
          </Grid>
          <Grid item xs={smallScreen ? 4 : 2} sx={{py: smallScreen ? 2 : 6}}>
            <FeaturedImageButton imageURI={UnconventionalWeddings} altText={""}
                                 url={"https://unconventionalwedding.co.uk/pop-punk-wedding/"}/>
          </Grid>
        </Grid>
      </Stack>
    </Container>
  </Box>
}

export default HomeFeatured;