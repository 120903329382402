import React from "react";
import {Box, Container, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

interface ReviewTextBoxProps {
  name: string;
  text: string;
  mobileText: string;
}

const ReviewTextBox = (props: ReviewTextBoxProps) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {name, text, mobileText} = props;
  return <Container disableGutters maxWidth={"lg"} sx={{
    position: "absolute",
    zIndex: 2,
    bottom: smallScreen ? "10vh" : 50,
    left: "50%",
    transform: "translateX(-50%)",
    px: 2
  }}>
    <Box sx={{
      backgroundColor: "rgb(248, 203, 156, 0.9)",
      p: smallScreen ? 2 : 4,
    }}>
      <Stack>
        {smallScreen && <>
          <Typography variant={'h5'} fontWeight={"bold"} paragraph textAlign={"center"}
                      sx={{textTransform: "uppercase", mb: 1}}
                      className={"\"secondary-font"}
                      letterSpacing={2}>{name}</Typography>
          <Stack direction={"row"} spacing={1} sx={{mb: 1, mx: "auto"}}>
            <StarIcon color={"info"} fontSize={"small"}/>
            <StarIcon color={"info"} fontSize={"small"}/>
            <StarIcon color={"info"} fontSize={"small"}/>
            <StarIcon color={"info"} fontSize={"small"}/>
            <StarIcon color={"info"} fontSize={"small"}/>
          </Stack>
          <Typography variant={"body1"}
                      className={"body-font"}
                      textAlign={"center"}>{mobileText}</Typography>
        </>}
        {!smallScreen && <>
          <Typography variant={'h3'} fontWeight={"bold"} paragraph
                      sx={{textTransform: "uppercase"}}
                      className={"header-font"} letterSpacing={6}>{name}</Typography>
          <Stack direction={"row"} spacing={2} sx={{pb: 2, mx: "auto"}}>
            <StarIcon color={"info"}/>
            <StarIcon color={"info"}/>
            <StarIcon color={"info"}/>
            <StarIcon color={"info"}/>
            <StarIcon color={"info"}/>
          </Stack>
          <Typography variant={"body1"} paragraph
                      className={"body-font"}
                      textAlign={"left"}>{text}</Typography>
        </>}
      </Stack>
    </Box>
  </Container>
}

export default ReviewTextBox;