import React from "react";
import BackgroundXs from "../../../assets/images/home/contact/holly-and-james-champagne-spray-600.webp"
import BackgroundSm from "../../../assets/images/home/contact/holly-and-james-champagne-spray-920.webp"
import BackgroundMd from "../../../assets/images/home/contact/holly-and-james-champagne-spray-1200.webp"
import BackgroundLg from "../../../assets/images/home/contact/holly-and-james-champagne-spray-1536.webp"
import BackgroundXl from "../../../assets/images/home/contact/holly-and-james-champagne-spray-2500.webp"
import {Box, Paper, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {delay} from "../../../shared/functions/delay";
import StyledTabButtonColoured from "../../../shared/button/styled-tab-button/styled-tab-button-coloured";

interface HomeContactProps {
  setNavigating: any
}

const HomeContact = (props: HomeContactProps) => {
  const {setNavigating} = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const mediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  async function navigateToLink(to: string) {
    setNavigating(true)
    await delay(500);
    navigate(to)
    setNavigating(false)
  }

  return <Box sx={{
    backgroundImage: {
      xs: `url(${BackgroundXs})`,
      sm: `url(${BackgroundSm})`,
      md: `url(${BackgroundMd})`,
      lg: `url(${BackgroundLg})`,
      xl: `url(${BackgroundXl})`,
    },
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundAttachment: smallScreen ? 'local' : "fixed",
    minHeight: smallScreen ? "60vh" : "80vh",
    width: "100vw",
    maxWidth: "100%"
  }}>
    <Paper sx={{
      background: "rgba(0, 0, 0, 0.3)",
      minHeight: smallScreen ? "60vh" : "80vh",
      Width: "100vw"
    }}>
      <Box sx={{
        display: 'flex',
        minHeight: smallScreen ? "60vh" : "80vh", alignItems: 'center',
        justifyContent: 'center'
      }}>
        <Stack spacing={smallScreen ? 4 : 8} sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textTransform: 'uppercase'
        }}>
          <Typography variant={smallScreen ? 'body1' : mediumScreen ? 'h4' : 'h3'} color={"white"} paragraph
                      className={"secondary-font-shadow"} fontWeight={smallScreen ? "bold" : "lighter"}
                      letterSpacing={smallScreen ? 4 : 6}
                      sx={{mb: 0}}>{`we're a perfect match?`}</Typography>
          <StyledTabButtonColoured onClick={() => navigateToLink("/contact")} text={"Let's Get Started"}/>
        </Stack>
      </Box>
    </Paper>
  </Box>
}

export default HomeContact;