import React, {useState} from "react";
import {Box, Fade, IconButton, useMediaQuery, useTheme} from "@mui/material";
import {TESTIMONIAL_DATA, TestimonialData} from "../review-data";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import {calculateTransitionTime, TransitionType} from "../../../../constants/transition-constants";
import ReviewTextBox from "../review-text-box/review-text-box";
import {delay} from "../../../../shared/functions/delay";
import HeartAndArrow from "../../../../assets/svg/Heart-2.svg";

interface CarouselButtonProps {
  onClick: any;
}

const CarouselNextButton = (props: CarouselButtonProps) => {
  return <IconButton onClick={props.onClick}
                     sx={{
                       position: "absolute",
                       height: "100px",
                       width: "100px",
                       right: 0,
                       top: "calc(50% - 50px)",
                       zIndex: 5
                     }}>
    <NavigateNextIcon fontSize={"large"}/>
  </IconButton>
}

const CarouselPrevButton = (props: CarouselButtonProps) => {
  return <IconButton onClick={props.onClick}
                     sx={{
                       position: "absolute",
                       height: "100px",
                       width: "100px",
                       left: 0,
                       top: "calc(50% - 50px)",
                       zIndex: 5
                     }}>
    <NavigateBeforeIcon fontSize={"large"}/>
  </IconButton>
}

const Carousel = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const xs = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const sm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const md = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const lg = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  const xl = useMediaQuery(theme.breakpoints.up('xl'));
  const [currentIndex, setCurrentIndex] = useState(0);
  const [transitioning, setTransitioning] = useState(false);
  const [initializing, setInitialized] = useState(true)

  const preloadImages = () => {
    /**
     * During initializing state, all carousel slides are loaded into the DOM.
     * This quick hack forces the browser to load all required images into memory.
     * After 50ms we set back the initializing state to false so the carousel only shows the active slide.
     */
    setTimeout(() => {
      setInitialized(false)
    }, 50)
  }
  preloadImages();

  async function next() {
    setTransitioning(true);
    await delay(50);
    if (currentIndex === TESTIMONIAL_DATA.length - 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
    setTransitioning(false);
  }

  async function prev() {
    setTransitioning(true);
    await delay(50);
    if (currentIndex === 0) {
      setCurrentIndex(TESTIMONIAL_DATA.length - 1);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
    setTransitioning(false);
  }

  function getAltText(review: TestimonialData): string {
    if (xl) {
      return review.altXl;
    } else if (lg) {
      return review.altLg;
    } else if (md) {
      return review.altMd;
    } else if (sm) {
      return review.altSm;
    } else if (xs) {
      return review.altXs;
    } else {
      return "";
    }
  }

  return <Box sx={{
    width: "100%",
    height: "100%",
    minHeight: mobile ? "min(100vh, 800px)" : "1000px",
    maxHeight: "100vh",
    position: "relative",
    backgroundColor: "rgb(30,30,30,)"
  }}>
    {!mobile && <img src={HeartAndArrow} alt={""} style={{
      position: "absolute",
      zIndex: 3,
      top: largeScreen ? "-150px" : "-125px",
      left: "5vw",
      width: largeScreen ? "400px" : "300px"
    }}/>}
    <div style={{
      position: "absolute",
      top: -5,
      left: 0,
      width: "100%",
      height: largeScreen ? "6vh" : "3vh",
      clipPath: "polygon(100% 0%, 0% 0% , 0.00% 100.00%, 2.00% 99.85%, 4.00% 99.39%, 6.00% 98.64%, 8.00% 97.59%, 10.00% 96.25%, 12.00% 94.64%, 14.00% 92.76%, 16.00% 90.64%, 18.00% 88.29%, 20.00% 85.72%, 22.00% 82.95%, 24.00% 80.02%, 26.00% 76.93%, 28.00% 73.72%, 30.00% 70.40%, 32.00% 67.01%, 34.00% 63.57%, 36.00% 60.11%, 38.00% 56.65%, 40.00% 53.22%, 42.00% 49.85%, 44.00% 46.57%, 46.00% 43.39%, 48.00% 40.34%, 50.00% 37.45%, 52.00% 34.74%, 54.00% 32.23%, 56.00% 29.94%, 58.00% 27.89%, 60.00% 26.08%, 62.00% 24.55%, 64.00% 23.30%, 66.00% 22.33%, 68.00% 21.66%, 70.00% 21.29%, 72.00% 21.22%, 74.00% 21.46%, 76.00% 22.01%, 78.00% 22.85%, 80.00% 23.98%, 82.00% 25.39%, 84.00% 27.08%, 86.00% 29.03%, 88.00% 31.22%, 90.00% 33.64%, 92.00% 36.27%, 94.00% 39.08%, 96.00% 42.06%, 98.00% 45.19%, 100.00% 48.43%)",
      backgroundColor: "rgba(248,203,156)",
      zIndex: 2
    }}/>
    <CarouselPrevButton onClick={prev}/>
    <CarouselNextButton onClick={next}/>
    {TESTIMONIAL_DATA.map((review, index) => {
      return <Fade in={initializing || (currentIndex === index && !transitioning)} timeout={500} mountOnEnter
                   unmountOnExit style={{
        transitionDuration: calculateTransitionTime([TransitionType.FAST]),
      }}>
        <Box sx={{
          width: "100%", height: "100%",
          backgroundColor: "rgb(30,30,30)",
          ...(initializing && {opacity: "0"})
        }}>
          <Box component={"img"} alt={getAltText(review)}
               sx={{
                 position: "absolute",
                 objectPosition: "center center",
                 ...((index === 1 || index === 3) && !mobile && {objectPosition: "top center"}),
                 objectFit: "cover",
                 height: "100%",
                 width: "100%",
                 top: 0,
                 left: 0,
                 zIndex: 1,
                 content: {
                   xs: `url(${review.imageXs})`,
                   sm: `url(${review.imageSm})`,
                   md: `url(${review.imageMd})`,
                   lg: `url(${review.imageLg})`,
                   xl: `url(${review.imageXl})`,
                 }
               }}/>
          <ReviewTextBox name={review.name} text={review.text} mobileText={review.mobileText}/>
        </Box>
      </Fade>
    })}
  </Box>
}

export default Carousel;