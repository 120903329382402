const WEDDING_IMAGES = [
    {image: require("./Clare & Rikki-350.webp"), fourByThree: false, altText: ""},
    {image: require("./bride-groom-showing-rings.webp"), fourByThree: false, altText: ""},
    {image: require("./226A1261.webp"), fourByThree: false, altText: ""},
    {image: require("./wedding-guests-arriving-black-and-white.webp"), fourByThree: true, altText: ""},
    {image: require("./226A1532.webp"), fourByThree: false, altText: ""},
    {image: require("./styled-19.webp"), fourByThree: false, altText: ""},
    {image: require("./bride-groom-aisle-confetti.webp"), fourByThree: false, altText: ""},
    {image: require("./bride-groom-kissing-outdoors-industrial.webp"), fourByThree: false, altText: ""},
    {image: require("./lifestyle-34.webp"), fourByThree: false, altText: ""},
    {image: require("./bride-groom-black-white-outdoors.webp"), fourByThree: false, altText: ""},
    {image: require("./wedding-guests-black-and-white-chequered-floor.webp"), fourByThree: false, altText: ""},
    {image: require("./styled-11.webp"), fourByThree: true, altText: ""},
    {image: require("./lifestyle-26.webp"), fourByThree: false, altText: ""},
    {image: require("./226A1580.webp"), fourByThree: false, altText: ""},
    {image: require("./bride-and-groom-confetti.webp"), fourByThree: false, altText: ""},
    {image: require("./lifestyle-29.webp"), fourByThree: false, altText: ""},
    {image: require("./groom-with-flowers-standing-outdoors.webp"), fourByThree: false, altText: ""},
    {image: require("./lifestyle-28.webp"), fourByThree: false, altText: ""},
    {image: require("./groom-customised-socks.webp"), fourByThree: true, altText: ""},
    {image: require("./wedding-guests-gathering-barn.webp"), fourByThree: true, altText: ""},
    {image: require("./wedding-guest-bride-father-crying.webp"), fourByThree: false, altText: ""},
    {image: require("./lifestyle-36.webp"), fourByThree: false, altText: ""},
    {image: require("./wedding-cake-outdoors.webp"), fourByThree: false, altText: ""},
    {image: require("./bride-groom-kiss-showing-off-rings.webp"), fourByThree: false, altText: ""},
    {image: require("./226A0149.webp"), fourByThree: false, altText: ""},
    {image: require("./bride-groom-congetti-laughing-black-and-white.webp"), fourByThree: true, altText: ""},
    {image: require("./styled-20.webp"), fourByThree: false, altText: ""},
    {image: require("./wedding-guests-speech-toast-black-and-white.webp"), fourByThree: true, altText: ""},
    {image: require("./bride-groom-dancing.webp"), fourByThree: false, altText: ""},
    {image: require("./6M0A8136.webp"), fourByThree: false, altText: ""},
    {image: require("./226A9539.webp"), fourByThree: false, altText: ""},
    {image: require("./wedding-guest-man-dancing-smiling.webp"), fourByThree: false, altText: ""},
    {image: require("./Steph & Joe-243.webp"), fourByThree: false, altText: ""},
    {image: require("./IMG_8512.webp"), fourByThree: false, altText: ""},
    {image: require("./bride-outdoor-embroidered-jacket.webp"), fourByThree: false, altText: ""},
    {image: require("./lifestyle-25.webp"), fourByThree: false, altText: ""},
    {image: require("./wedding-ceremony-setup-cards-box-florals.webp"), fourByThree: true, altText: ""},
    {image: require("./lifestyle-1.webp"), fourByThree: false, altText: ""},
    {image: require("./styled-12.webp"), fourByThree: false, altText: ""},
    {image: require("./bride-groom-rain-umbrella.webp"), fourByThree: false, altText: ""},
    {image: require("./226A0232.webp"), fourByThree: false, altText: ""},
    {image: require("./Steph & Joe-324.webp"), fourByThree: false, altText: ""},
    {image: require("./styled-39.webp"), fourByThree: true, altText: ""},
    {image: require("./bride-friends-congratulating.webp"), fourByThree: false, altText: ""},
    {image: require("./Steph & Joe-169.webp"), fourByThree: false, altText: ""},
    {image: require("./226A0132.webp"), fourByThree: false, altText: ""},
    {image: require("./styled-38.webp"), fourByThree: true, altText: ""},
    {image: require("./wedding-guests-party-relaxed.webp"), fourByThree: true, altText: ""},
    {image: require("./This Modern Love-097.webp"), fourByThree: false, altText: ""},
    {image: require("./226A0121.webp"), fourByThree: true, altText: ""},
    {image: require("./wedding-guests-barn-aisle.webp"), fourByThree: true, altText: ""},
    {image: require("./styled-27.webp"), fourByThree: false, altText: ""},
    {image: require("./groom-party-outdoor-laughing.webp"), fourByThree: false, altText: ""},
    {image: require("./wedding-guest-downing-drink.webp"), fourByThree: false, altText: ""},
    {image: require("./Steph & Joe-379.webp"), fourByThree: true, altText: ""},
    {image: require("./styled-18.webp"), fourByThree: false, altText: ""},
    {image: require("./lifestyle-13.webp"), fourByThree: true, altText: ""},
    {image: require("./226A6541.webp"), fourByThree: false, altText: ""},
    {image: require("./wedding-guests-shots-black-and-white.webp"), fourByThree: true, altText: ""},
    {image: require("./6M0A1739.webp"), fourByThree: false, altText: ""},
    {image: require("./226A0692.webp"), fourByThree: false, altText: ""},
    {image: require("./lifestyle-2.webp"), fourByThree: true, altText: ""},
    {image: require("./This Modern Love-044.webp"), fourByThree: false, altText: ""},
    {image: require("./IMG_8622.webp"), fourByThree: false, altText: ""},
    {image: require("./6M0A8049.webp"), fourByThree: false, altText: ""},
    {image: require("./226A0173.webp"), fourByThree: false, altText: ""},
    {image: require("./bride-party-partying-black-and-white.webp"), fourByThree: true, altText: ""},
    {image: require("./Steph & Joe-128.webp"), fourByThree: false, altText: ""},
    {image: require("./styled-24.webp"), fourByThree: true, altText: ""},
    {image: require("./wedding-guests-dancefloor.webp"), fourByThree: false, altText: ""},
    {image: require("./styled-34.webp"), fourByThree: false, altText: ""},
    {image: require("./groom-shoes-ring-pocket-watch-flat-lay.webp"), fourByThree: false, altText: ""},
    {image: require("./lifestyle-16.webp"), fourByThree: false, altText: ""},
    {image: require("./styled-25.webp"), fourByThree: false, altText: ""},
    {image: require("./lifestyle-7.webp"), fourByThree: false, altText: ""},
    {image: require("./styled-40.webp"), fourByThree: false, altText: ""},
    {image: require("./bride-groom-inside-teepee.webp"), fourByThree: false, altText: ""},
    {image: require("./IMG_8848.webp"), fourByThree: false, altText: ""},
    {image: require("./lifestyle-39.webp"), fourByThree: false, altText: ""},
    {image: require("./groom-fixing-tie-black-and-white.webp"), fourByThree: false, altText: ""},
    {image: require("./Steph & Joe-102.webp"), fourByThree: true, altText: ""},
    {image: require("./couple-coffee-shop.webp"), fourByThree: false, altText: ""},
    {image: require("./Steph & Joe-123.webp"), fourByThree: false, altText: ""},
    {image: require("./Clare & Rikki-056.webp"), fourByThree: false, altText: ""},
    {image: require("./Savannah & Mark-027.webp"), fourByThree: false, altText: ""},
    {image: require("./lifestyle-41.webp"), fourByThree: false, altText: ""},
    {image: require("./226A1730.webp"), fourByThree: false, altText: ""},
    {image: require("./Clare & Rikki-468.webp"), fourByThree: false, altText: ""},
    {image: require("./Savannah & Mark-052.webp"), fourByThree: false, altText: ""},
    {image: require("./Steph & Joe-403.webp"), fourByThree: false, altText: ""},
    {image: require("./This Modern Love-147.webp"), fourByThree: false, altText: ""},
];

export default WEDDING_IMAGES;