import React from "react";
import {Box, Container, Grid, useMediaQuery, useTheme} from "@mui/material";
import {BackgroundType} from "../background-type";
import InvestmentPackageDetails from "../investment-package-details/investment-package-details";

export interface InvestmentPackageProps {
  title: string;
  description: string;
  text: string[];
  price: string;
  graphic: any;
  backgroundType: BackgroundType;
  image?: any;
  topDivider?: boolean;
  removeIcons?: boolean
}

interface PhotoProps {
  src: any;
  sx?: any;
}

const Photo = (props: PhotoProps) => {
  const theme = useTheme();
  const mediumOrSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  return <Box sx={{
    width: "100%",
    ...(!mediumOrSmallScreen && {height: "800px"}),
    ...(mediumOrSmallScreen && {height: "calc((100vw * 2 / 3) - 1px)"}),
    ...(props.sx)
  }}>
    <img src={props.src} alt={''} style={{
      width: "100%",
      ...(!mediumOrSmallScreen && {height: "900px"}),
      objectPosition: "center center",
      objectFit: "cover"
    }}/>
  </Box>
}

const InvestmentPackage = (props: InvestmentPackageProps) => {
  const {image, backgroundType} = props;
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const mediumOrSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  return <>
    <Box id={props.title + "-container"} sx={{
      width: "100%",
      maxWidth: "100%",
      ...(!mediumOrSmallScreen && !!image && {height: "900px"}),
      ...(backgroundType === BackgroundType.YELLOW && {backgroundColor: "rgba(248,203,156)"}),
    }}>
      {!!image && !mediumOrSmallScreen &&
          <Photo src={image} sx={{
            position: "absolute", maxWidth: "50%",
            ...(backgroundType === BackgroundType.YELLOW && {right: 0}),
            ...(backgroundType === BackgroundType.GREY && {left: 0}),

          }}/>
      }
      <Container maxWidth={false} sx={{py: smallScreen ? 6 : 8, height: "100%"}}>
        <Grid container spacing={4} sx={{height: "100%"}}>
          {!!image && !mediumOrSmallScreen && backgroundType === BackgroundType.GREY &&
              <Grid item xs={6}/>}
          <Grid item xs={!image || mediumOrSmallScreen ? 12 : 6} alignItems={"center"} justifyContent={"center"}>
            <InvestmentPackageDetails {...props} />
          </Grid>
        </Grid>
      </Container>
      {!!image && mediumOrSmallScreen && props.title !== "THE SIMPLY WED" && <Photo src={image}/>}
    </Box>
  </>
}

export default InvestmentPackage;