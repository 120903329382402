import React from "react";
import {
  Box,
  Container,
  Divider,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {BackgroundType} from "../background-type";
import FavoriteIcon from "@mui/icons-material/Favorite";
import {InvestmentPackageProps} from "../investment-package/investment-package";

const InvestmentPackageDetails = (props: InvestmentPackageProps) => {
  const {title, graphic, description, text, price, topDivider, backgroundType, removeIcons} = props;
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return <Box sx={{
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }}>
    <Container maxWidth={"sm"} sx={{height: "100%"}}>
      {topDivider &&
          <Divider variant={"middle"} color={"white"} sx={{mt: smallScreen ? -6 : -8, mb: smallScreen ? 6 : 8}}/>}
      <Stack sx={{width: "100%"}} alignItems={"center"} justifyContent={"center"}
             spacing={2}>
        <Typography variant={"h6"}
                    color={backgroundType === BackgroundType.YELLOW ? "primary" : "white"}
                    paragraph
                    className={"header-font"}
                    letterSpacing={6}
                    fontWeight={"bold"}>{title}</Typography>
        {!!graphic && <img src={graphic} alt={''} style={{maxWidth: "100px", margin: 0}}/>}
        {!!description && <>
          <Typography variant={"body1"} className={"body-font"}
                      color={backgroundType === BackgroundType.YELLOW ? "primary" : "white"}
                      paragraph>
            {description}
          </Typography>
          <Typography variant={"h3"} className={"scripted-font-alternate"}
                      color={backgroundType === BackgroundType.YELLOW ? "primary" : "white"}
                      paragraph>
            What's included
          </Typography>
        </>
        }
        <List disablePadding>
          {text.map((textItem, index) => <ListItem key={title + "-text-item-" + index} alignItems={"flex-start"}>
            {!removeIcons && <ListItemIcon sx={{mt: smallScreen ? 0.15 : 0.25}}>
              <Icon sx={{color: "rgb(187,57,60)"}}><FavoriteIcon fontSize={smallScreen ? "small" : "inherit"}/></Icon>
            </ListItemIcon>}
            <ListItemText>
              <Typography variant={"body1"} className={"body-font"}
                          color={backgroundType === BackgroundType.YELLOW ? "primary" : "white"}
                          paragraph textAlign={"left"}>
                {textItem}
              </Typography>
            </ListItemText>
          </ListItem>)}
        </List>
        <Typography variant={"h6"} color={backgroundType === BackgroundType.YELLOW ? "primary" : "white"}
                    paragraph
                    className={"header-font"}
                    letterSpacing={6}
                    fontWeight={"bold"}>{price}</Typography>
      </Stack>
    </Container>
  </Box>
}

export default InvestmentPackageDetails;