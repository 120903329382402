import React from "react";
import {Box, Container, Fade, Paper, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import {calculateTransitionTime, TransitionType} from "../../../constants/transition-constants";
import BackgroundMobile from "../../../assets/images/home/title/title-600.webp";
import BackgroundDesktop from "../../../assets/images/home/background-min.webp";
import MainLogo from "../../../assets/images/logos/logo-large/logo-white-red.png"


const HomeTitle = () => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const mediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
  return <Fade in={true} style={{
    transitionDuration: calculateTransitionTime([TransitionType.SLOW])
  }}>
    <Paper sx={{
      backgroundImage: {
        xs: `url(${BackgroundMobile})`,
        sm: `url(${BackgroundDesktop})`,
      },
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundAttachment: smallScreen ? 'local' : 'fixed',
      height: "100vh",
      width: "100vw",
      maxWidth: "100%",
      position: "relative"
    }}>
      <div style={{
        width: "100%",
        height: smallScreen ? "25px" : "50px",
        clipPath: smallScreen ? "polygon(0 0, 100% 60%, 100% 100%, 0% 100%)" : "polygon(0 0, 100% 80%, 100% 100%, 0% 100%)",
        backgroundColor: "rgba(248,203,156)",
        position: "absolute",
        bottom: "-10px"
      }}/>
      <Paper sx={{
        background: "rgba(0, 0, 0, 0.3)",
        height: "100vh",
        width: "100vw",
        maxWidth: "100%"
      }}>
        <Container>
          <Fade in={true} style={{
            transitionDuration: calculateTransitionTime([TransitionType.SLOW]),
            transitionDelay: calculateTransitionTime([TransitionType.SLOW])
          }}>
            <Box sx={{
              display: 'flex',
              height: "100vh",
              width: "100vw",
              maxWidth: "100%",
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <Stack spacing={0} sx={{
                width: "100%",
                height: "100%",
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <img src={MainLogo} alt={''} style={{
                  ...(smallScreen && {height: '70%'}),
                  ...(mediumScreen && {height: '50%'}),
                  ...(largeScreen && {height: '60%'}),
                  maxWidth: smallScreen ? "80%" : "100%",
                  objectFit: "contain",
                  objectPosition: "center center"
                }}/>
                <Typography variant={smallScreen ? 'body1' : mediumScreen ? 'h4' : 'h3'}
                            fontWeight={smallScreen ? "unset" : "lighter"}
                            letterSpacing={smallScreen ? 4 : 6} sx={{pb: 2, mt: -8}}>FOR THE FUN-LOVING</Typography>
                <Typography variant={smallScreen ? 'body1' : mediumScreen ? 'h4' : 'h3'}
                            fontWeight={smallScreen ? "unset" : "lighter"}
                            letterSpacing={smallScreen ? 4 : 6}>& UNTRADITIONAL</Typography>
              </Stack>
            </Box>
          </Fade>
        </Container>
      </Paper>
    </Paper>
  </Fade>
}

export default HomeTitle;