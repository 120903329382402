import React from "react";
import BackgroundXs
  from "../../../assets/images/home/investment/hackney-town-hall-wedding/hackney-town-hall-wedding-600.webp";
import BackgroundSm
  from "../../../assets/images/home/investment/hackney-town-hall-wedding/hackney-town-hall-wedding-920.webp";
import BackgroundMd
  from "../../../assets/images/home/investment/hackney-town-hall-wedding/hackney-town-hall-wedding-1200.webp";
import BackgroundLg
  from "../../../assets/images/home/investment/hackney-town-hall-wedding/hackney-town-hall-wedding-1536.webp";
import BackgroundXl
  from "../../../assets/images/home/investment/hackney-town-hall-wedding/hackney-town-hall-wedding-2500.webp";
import {Box, Paper, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {delay} from "../../../shared/functions/delay";
import StyledTabButtonColoured from "../../../shared/button/styled-tab-button/styled-tab-button-coloured";

interface HomeInvestmentProps {
  setNavigating: any
}

const HomeInvestment = (props: HomeInvestmentProps) => {
  const {setNavigating} = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const mediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

  async function navigateToLink(to: string) {
    setNavigating(true)
    await delay(500);
    navigate(to)
    setNavigating(false)
  }

  return <Box component={"div"} sx={{
    backgroundAttachment: mobile ? 'local' : "fixed",
    backgroundImage: {
      xs: `url(${BackgroundXs})`,
      sm: `url(${BackgroundSm})`,
      md: `url(${BackgroundMd})`,
      lg: `url(${BackgroundLg})`,
      xl: `url(${BackgroundXl})`,
    },
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    maxWidth: "100%",
    minHeight: mobile ? "60vh" : "80vh",
    position: "relative",
    width: "100vw"
  }}>
    <Box component={"div"} sx={{
      position: "absolute",
      bottom: -5,
      left: 0,
      width: "100%",
      height: largeScreen ? "6vh" : "3vh",
      clipPath: "polygon(100% 100%, 0% 100% , 0.00% 90.00%, 2.00% 89.83%, 4.00% 89.33%, 6.00% 88.50%, 8.00% 87.35%, 10.00% 85.88%, 12.00% 84.10%, 14.00% 82.04%, 16.00% 79.71%, 18.00% 77.11%, 20.00% 74.29%, 22.00% 71.25%, 24.00% 68.02%, 26.00% 64.62%, 28.00% 61.09%, 30.00% 57.44%, 32.00% 53.71%, 34.00% 49.93%, 36.00% 46.12%, 38.00% 42.32%, 40.00% 38.55%, 42.00% 34.84%, 44.00% 31.22%, 46.00% 27.72%, 48.00% 24.37%, 50.00% 21.20%, 52.00% 18.21%, 54.00% 15.45%, 56.00% 12.93%, 58.00% 10.67%, 60.00% 8.69%, 62.00% 7.01%, 64.00% 5.63%, 66.00% 4.56%, 68.00% 3.83%, 70.00% 3.42%, 72.00% 3.35%, 74.00% 3.61%, 76.00% 4.21%, 78.00% 5.13%, 80.00% 6.38%, 82.00% 7.93%, 84.00% 9.79%, 86.00% 11.93%, 88.00% 14.34%, 90.00% 17.00%, 92.00% 19.89%, 94.00% 22.99%, 96.00% 26.27%, 98.00% 29.71%, 100.00% 33.28%)",
      backgroundColor: "rgba(248,203,156)",
    }}/>
    <Paper sx={{
      background: "rgba(0, 0, 0, 0.3)",
      minHeight: mobile ? "60vh" : "80vh",
      Width: "100vw"
    }}>
      <Box sx={{
        display: 'flex',
        minHeight: mobile ? "60vh" : "80vh", alignItems: 'center',
        justifyContent: 'center'
      }}>
        <Stack spacing={mobile ? 4 : 8} sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textTransform: 'uppercase'
        }}>
          <Typography variant={mobile ? 'body1' : mediumScreen ? 'h4' : 'h3'} color={"white"} paragraph
                      className={"secondary-font-shadow"} fontWeight={mobile ? "bold" : "lighter"}
                      letterSpacing={mobile ? 4 : 6}
                      sx={{mb: 0}}>{`Good times, fun vibes.`}</Typography>
          <StyledTabButtonColoured onClick={() => navigateToLink("/pricing")} text={"Check out my pricing guide"}/>
        </Stack>
      </Box>
    </Paper>
  </Box>
}

export default HomeInvestment;