import ClareAndRikkiXs from "../../../assets/images/home/review/clare-and-rikki/clare-and-rikki-600.webp";
import ClareAndRikkiSm from "../../../assets/images/home/review/clare-and-rikki/clare-and-rikki-920.webp";
import ClareAndRikkiMd from "../../../assets/images/home/review/clare-and-rikki/clare-and-rikki-1200.webp";
import ClareAndRikkiLg from "../../../assets/images/home/review/clare-and-rikki/clare-and-rikki-1536.webp";
import ClareAndRikkiXl from "../../../assets/images/home/review/clare-and-rikki/clare-and-rikki-2500.webp";
import StephAndJoeXs from "../../../assets/images/home/review/steph-and-joe/steph-and-joe-600.webp";
import StephAndJoeSm from "../../../assets/images/home/review/steph-and-joe/steph-and-joe-920.webp";
import StephAndJoeMd from "../../../assets/images/home/review/steph-and-joe/steph-and-joe-1200.webp";
import StephAndJoeLg from "../../../assets/images/home/review/steph-and-joe/steph-and-joe-1536.webp";
import StephAndJoeXl from "../../../assets/images/home/review/steph-and-joe/steph-and-joe-2500.webp";
import SophieAndRobXs from "../../../assets/images/home/review/sophie-and-rob/sophie-and-rob-600.webp";
import SophieAndRobSm from "../../../assets/images/home/review/sophie-and-rob/sophie-and-rob-920.webp";
import SophieAndRobMd from "../../../assets/images/home/review/sophie-and-rob/sophie-and-rob-1200.webp";
import SophieAndRobLg from "../../../assets/images/home/review/sophie-and-rob/sophie-and-rob-1536.webp";
import SophieAndRobXl from "../../../assets/images/home/review/sophie-and-rob/sophie-and-rob-2500.webp";
import CarleeAndEzekielXs from "../../../assets/images/home/review/carlee-and-ezekiel/carlee-and-ezekiel-600.webp";
import CarleeAndEzekielSm from "../../../assets/images/home/review/carlee-and-ezekiel/carlee-and-ezekiel-920.webp";
import CarleeAndEzekielMd from "../../../assets/images/home/review/carlee-and-ezekiel/carlee-and-ezekiel-1200.webp";
import CarleeAndEzekielLg from "../../../assets/images/home/review/carlee-and-ezekiel/carlee-and-ezekiel-1536.webp";
import CarleeAndEzekielXl from "../../../assets/images/home/review/carlee-and-ezekiel/carlee-and-ezekiel-2500.webp";
import SarahAndJackXs from "../../../assets/images/home/review/sarah-and-jack/sarah-and-jack-600.webp";
import SarahAndJackSm from "../../../assets/images/home/review/sarah-and-jack/sarah-and-jack-920.webp";
import SarahAndJackMd from "../../../assets/images/home/review/sarah-and-jack/sarah-and-jack-1200.webp";
import SarahAndJackLg from "../../../assets/images/home/review/sarah-and-jack/sarah-and-jack-1536.webp";
import SarahAndJackXl from "../../../assets/images/home/review/sarah-and-jack/sarah-and-jack-2500.webp";

export interface TestimonialData {
  imageXs: any;
  imageSm: any;
  imageMd: any;
  imageLg: any;
  imageXl: any;
  altXs: string;
  altSm: string;
  altMd: string;
  altLg: string;
  altXl: string;
  name: string;
  text: string;
  mobileText: string;
}

export const TESTIMONIAL_DATA: TestimonialData[] = [
  {
    imageXs: ClareAndRikkiXs,
    imageSm: ClareAndRikkiSm,
    imageMd: ClareAndRikkiMd,
    imageLg: ClareAndRikkiLg,
    imageXl: ClareAndRikkiXl,
    altXs: "Untraditional bride and groom about to kiss at a pop-punk wedding, with a red wedding dress at the River Mill in Derby",
    altSm: "Untraditional bride and groom at a pop-punk wedding, with a red wedding dress at the River Mill in Derby",
    altMd: "Untraditional bride and groom at a pop-punk wedding, with a red wedding dress at the River Mill in Derby",
    altLg: "Untraditional bride and groom at a pop-punk wedding, with a red wedding dress at the River Mill in Derby",
    altXl: "Untraditional bride and groom at a pop-punk wedding, with a red wedding dress at the River Mill in Derby",
    name: "Clare & Rikki",
    text: `"It would be impossible not to love her. She's a rare human who has many qualities - extreme talent, 
            style and the BIGGEST heart. If anyone is deciding between a few photographers that include Marilyn, pick her, 
            you won't regret it. She made us feel like the only couple in the world, the most important people on our 
            most special day and her photographs speak for themselves. She captured our vibe perfectly and having her 
            spend the day with us was a dream. She doesn't miss a beat, even having only met a few times, it really 
            felt like she knew exactly who we are. There wasn't a single part of our day that she didn't capture 
            beautifully, honestly I'm not sure she isn't magic"`,
    mobileText: `"She's a rare human who has many qualities - extreme talent, 
            style and the BIGGEST heart."`,
  },
  {
    imageXs: StephAndJoeXs,
    imageSm: StephAndJoeSm,
    imageMd: StephAndJoeMd,
    imageLg: StephAndJoeLg,
    imageXl: StephAndJoeXl,
    altXs: "Newlyweds walking down the aisle at an indie-inspired wedding at Wylam Brewery in Newcastle",
    altSm: "Newlyweds and bridesmaids at an indie-inspired wedding at Wylam Brewery in Newcastle",
    altMd: "Newlyweds and bridesmaids at an indie-inspired wedding at Wylam Brewery in Newcastle",
    altLg: "Newlyweds and bridesmaids at an indie-inspired wedding at Wylam Brewery in Newcastle",
    altXl: "Newlyweds and bridesmaids at an indie-inspired wedding at Wylam Brewery in Newcastle",
    name: "steph & joe",
    text: `"Wow, wow, wow! Where do we even begin? From the second we enquired with Marilyn right up to now she has been
            the most AMAZING person to work with. She made everything so relaxed, fun and natural and we've ended up with the
            most amazing sneak peak gallery which is better than we could ever have wished for. Not only does she bring the laid
            back and fun vibes, but she just has THE MOST amazing vision and eye for detail. She has captured EVERYTHING from
            our day so perfectly, from the details, to the venue and has captured the vibe so unbelievably perfectly.
            We absolutely couldn't recommend her enough and are so so grateful!"`,
    mobileText: `"Not only does she bring the laid
            back and fun vibes, but she just has THE MOST amazing vision and eye for detail"`,
  },
  {
    imageXs: SophieAndRobXs,
    imageSm: SophieAndRobSm,
    imageMd: SophieAndRobMd,
    imageLg: SophieAndRobLg,
    imageXl: SophieAndRobXl,
    altXs: "Bride and groom together with their dog during intimate wedding at the River Mill in Derby",
    altSm: "Bride and groom kissing during intimate wedding at the River Mill in Derby",
    altMd: "Bride and groom kissing during intimate wedding at the River Mill in Derby",
    altLg: "Bride and groom kissing during intimate wedding at the River Mill in Derby",
    altXl: "Bride and groom kissing during intimate wedding at the River Mill in Derby",
    name: "sophie & rob",
    text: `"Marilyn has been amazing throughout from the moment we chose her to when we got the images back.
            Straight away we were on the same page and she was so easy to contact all the while.
            On the big day she was very cool, relaxed and fun and really captured every moment with perfection.
            We have been so impressed at how super quick the photos have been sent to us and they have come out amazing.
            We had no doubt anyway. Thank you a million times again!"`,
    mobileText: `"She was very cool, relaxed and fun and really captured every moment with perfection"`,
  },
  {
    imageXs: CarleeAndEzekielXs,
    imageSm: CarleeAndEzekielSm,
    imageMd: CarleeAndEzekielMd,
    imageLg: CarleeAndEzekielLg,
    imageXl: CarleeAndEzekielXl,
    altXs: "Bride and Groom together outside at Barton's Mill near Basingstoke",
    altSm: "Newlywed couple outdoors with family wearing sage suits at Barton's Mill near Basingstoke",
    altMd: "Newlywed couple outdoors with family wearing sage suits at Barton's Mill near Basingstoke",
    altLg: "Newlywed couple outdoors with family wearing sage suits at Barton's Mill near Basingstoke",
    altXl: "Newlywed couple outdoors with family wearing sage suits at Barton's Mill near Basingstoke",
    name: "carlee & ezekiel",
    text: `"I hired Marilyn to do the photography for my wedding and honestly I could not imagine the day with anyone else by my side.
            She was so energetic and friendly with everyone. Our family and friends all commented how lovely she was after the wedding.
            THEN we got the photos back… and they were beyond amazing, she captured every moment perfectly and beautifully.
            My husband and I have already decided she will be our photographer for any other events and special moments we need captured.
            Thank you so much Marilyn for being there with us."`,
    mobileText: `"I could not imagine the day with anyone else by my side. Thank you so much Marilyn for being there with us"`,
  },
  {
    imageXs: SarahAndJackXs,
    imageSm: SarahAndJackSm,
    imageMd: SarahAndJackMd,
    imageLg: SarahAndJackLg,
    imageXl: SarahAndJackXl,
    altXs: "Bride and groom walking through confetti at DIY barn wedding in Derbyshire",
    altSm: "Bride and groom at DIY barn wedding in Derbyshire",
    altMd: "Bride and groom at DIY barn wedding in Derbyshire",
    altLg: "Bride and groom at DIY barn wedding in Derbyshire",
    altXl: "Bride and groom at DIY barn wedding in Derbyshire",
    name: "sarah & jack",
    text: `"Marilyn went above and beyond to capture the most incredible photos of our wedding day. 
            From trudging through wet muddy fields to climbing over rickety gates so that I could get my horse girl pics. 
            She instantly made myself and (camera shy) husband feel at ease in front of the lens. 
            She has a warmth to her that makes you feel like you’ve known her for years, 
            we shared so many laughs throughout the course of the day! We will forever be grateful for 
            the beautiful moments and memories that Marilyn has captured."`,
    mobileText: `"Marilyn went above and beyond to capture the most incredible photos"`,
  }
]