import React from "react";
import {Fade} from "@mui/material";
import HomeTitle from "./title/home-title";
import HomeTestimonials from "./testimonial/home-testimonials";
import HomeContact from "./contact/home-contact";
import FooterDesktop from "../../shared/footer/footer-desktop";
import HomeAbout from "./about/home-about";
import HomeInvestment from "./investment/home-investment";
import HomeFeatured from "./featured/home-featured";

interface HomeProps {
  navigating: boolean;
  setNavigating: any;
}

const Home = (props: HomeProps) => {
  const {navigating, setNavigating} = props;
  return <>
    <Fade in={!navigating} timeout={500}>
      <div>
        <HomeTitle/>
        <HomeAbout setNavigating={setNavigating}/>
        <HomeInvestment setNavigating={setNavigating}/>
        <HomeTestimonials/>
        <HomeFeatured/>
        <HomeContact setNavigating={setNavigating}/>
        <FooterDesktop setNavigating={setNavigating}/>
      </div>
    </Fade>
  </>
}

export default Home;