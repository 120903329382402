import React from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import {Button, Container, IconButton, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import {useNavigate} from "react-router-dom";
import useScript from "../../hooks/use-script";
import Logo from "../../assets/images/logos/logo-small/logo-white-red.png";
import {delay} from "../functions/delay";
import {navigateToFacebook, navigateToInstagram} from "../functions/new-tab-links";

interface FooterDesktopProps {
  setNavigating: any;
}

const year = new Date().getFullYear()

const FooterDesktop = (props: FooterDesktopProps) => {
  const {setNavigating} = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const mediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  useScript('https://snapwidget.com/js/snapwidget.js')

  async function navigateToLink(to: string) {
    setNavigating(true)
    await delay(500);
    navigate(to)
    setNavigating(false)
  }

  return <Container maxWidth={"lg"} sx={{py: 4}}>
    <Grid2 container spacing={2}>
      <Grid2 xs={12}>
        <Typography variant={"h6"} color={"white"} paragraph className={"header-font"} letterSpacing={6}
                    sx={{mb: 4}}><strong>...</strong></Typography>
        <Typography variant={"h6"} color={"white"} paragraph className={"header-font"}
                    letterSpacing={6}><strong>INSTA</strong></Typography>
        <iframe title={"Instagram"} src="https://snapwidget.com/embed/1012987" className={"snapwidget-widget"}
                frameBorder={"0"} scrolling="no"
                style={{border: "none", overflow: "hidden", maxWidth: "100%", width: "765px"}}></iframe>
      </Grid2>
      <Grid2 xs={12}>
        <Stack direction={smallScreen ? "column" : "row"} spacing={2} justifyContent={"center"}>
          <Button size={"large"} sx={{color: 'white'}}
                  onClick={() => navigateToLink("/")}>Home</Button>
          <Button size={"large"} sx={{color: 'white'}}
                  onClick={() => navigateToLink("/about")}>About</Button>
          <Button size={"large"} sx={{color: 'white'}}
                  onClick={() => navigateToLink("/portfolio")}>Portfolio</Button>
          <Button size={"large"} sx={{color: 'white'}}
                  onClick={() => navigateToLink("/pricing")}>Pricing</Button>
          <Button size={"large"} sx={{color: 'white'}}
                  onClick={() => navigateToLink("/contact")}>Contact</Button>
        </Stack>
      </Grid2>
      <Grid2 xs={12}>
        <IconButton size={"large"} onClick={navigateToInstagram}>
          <InstagramIcon/>
        </IconButton>
        <IconButton size={"large"} onClick={navigateToFacebook}>
          <FacebookIcon/>
        </IconButton>
      </Grid2>
      {!smallScreen &&
          <>
            <Grid2 xs={4} sx={{display: 'flex', alignItems: 'center', justifyContent: 'left'}}>
              <Typography variant={"body1"} color={"white"}
                          sx={{fontSize: mediumScreen ? "0.8rem" : "1rem"}}>&copy; Marilyn Denise
                Photography {year}</Typography>
            </Grid2>
            <Grid2 xs={4}>
              <img src={Logo} alt={''} style={{
                maxHeight: "80px"
              }}/>
            </Grid2>
            <Grid2 xs={4} sx={{display: 'flex', alignItems: 'center', justifyContent: 'right'}}>
              <Typography variant={"body1"} color={"white"} sx={{fontSize: mediumScreen ? "0.8rem" : "1rem"}}>All Rights
                Reserved</Typography>
            </Grid2>
          </>}
      {smallScreen &&
          <Stack sx={{width: "100vw", maxWidth: "100%", alignItems: "center", justifyContent: "center"}}>
            <img src={Logo} alt={''} style={{
              height: "80px",
              width: "80px"
            }}/>
            <Typography variant={"body1"} color={"white"} sx={{fontSize: "1rem"}}>&copy; Marilyn Denise
              Photography {year}</Typography>
            <Typography variant={"body1"} color={"white"} sx={{fontSize: "1rem"}}>All Rights Reserved</Typography>
          </Stack>}
    </Grid2>
  </Container>
}

export default FooterDesktop;