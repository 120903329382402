import React, {useState} from "react";
import {ImageListItem, Skeleton} from "@mui/material";

export interface loadingImageProps {
  src: string,
  transition: string;
  fourByThree: boolean;
  altText?: string;
}

const LoadingImageListItem = (props: loadingImageProps) => {
  const [loaded, setLoaded] = useState(false);
  return <ImageListItem sx={{display: "block", breakInside: "avoid"}}>
    <div style={{height: 0, paddingTop: props.fourByThree ? "66.66%" : "150%", overflow: "hidden"}}>
      {!loaded && <Skeleton variant={"rectangular"} height={"100%"} width={"100%"} sx={{position: "absolute", top: 0, left: 0}}/>}
      <img src={props.src}
             loading={"lazy"}
             alt={props.altText ? props.altText : ""}
             height={"100%"}
             width={"100%"}
             style={{objectFit: "contain", position: "absolute", top: 0, left: 0}}
             onLoad={() => setLoaded(true)}/>
    </div>
  </ImageListItem>
}

export default LoadingImageListItem;