import React, {useEffect, useState} from "react"
import Background from "../../assets/images/contact/background.webp"
import {
  Box,
  Button,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Fade,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {useForm} from "react-hook-form";
import emailjs from "@emailjs/browser"
import {useNavigate} from "react-router-dom";
import {calculateTransitionTime, TransitionType} from "../../constants/transition-constants";
import WeddingForm from "./form/wedding-form";
import {Moment} from "moment";
import LifestyleForm from "./form/lifestyle-form";
import {useTitle} from "../../hooks/use-title";
import FooterDesktop from "../../shared/footer/footer-desktop";
import Signature from "../../assets/images/contact/signature.png"
import {delay} from "../../shared/functions/delay";

interface ContactProps {
  navigating: boolean;
  setNavigating: any;
}

const defaults: any = {} as FormData

enum FormType {
  NONE,
  WEDDING,
  LIFESTYLE
}

const Contact = (props: ContactProps) => {
  useTitle('Contact Me | UK Alternative Wedding Photographer | Midlands-Based Wedding Photography')
  const {navigating, setNavigating} = props;
  const [formType, setFormType] = useState(FormType.WEDDING);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {handleSubmit, reset, formState, control/*TODO, formState: {errors}*/} = useForm({defaultValues: defaults});
  const submit = (data: Record<string, unknown> | undefined) => {
    const date = data?.date as Moment
    const formattedData = {
      ...data,
      date: date ? date.format("DD/MM/YYYY") : ''
    }
    if (data) {
      if (formType === FormType.WEDDING) {
        emailjs.send("service_q8kmh7l", "template_d7t7c74", formattedData, "qVEKyZ-QBmiAEESNq")
            .then(() => setSubmitted(true));
      } else if (formType === FormType.LIFESTYLE) {
        emailjs.send("service_q8kmh7l", "template_hgdvx18", formattedData, "qVEKyZ-QBmiAEESNq")
            .then(() => setSubmitted(true));
      } else {
        return Promise.reject("No correct formType selected")
      }
    } else {
      return Promise.reject("Data not available")
    }
  }

  async function navigateToLink(to: string) {
    setNavigating(true)
    await delay(500);
    navigate(to)
    setNavigating(false)
  }

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset()
    }
  }, [formState, reset])

  return <>
    <Fade in={!navigating} timeout={500} appear={false}>
      <div>
        <Container maxWidth="sm" sx={{pt: smallScreen ? 12 : 16}}>
          <Fade in={true} style={{
            transitionDuration: calculateTransitionTime([TransitionType.SLOW])
          }}>
            <Stack sx={{width: "100%"}} alignItems={"center"} spacing={4}>
              <Typography variant={"h6"} color={"white"} paragraph className={"header-font"}
                          letterSpacing={6}
                          fontWeight={"bold"}>YOU <span style={{color: 'rgb(187,57,60)'}}>LOVE</span> WHAT YOU SEE, AND
                YOU THINK YOU WANNA BOOK ME?</Typography>
              <Typography variant={"body1"} color={"white"} paragraph align={'left'} className={"body-font"}>{
                `Using this contact form, please make sure you are as detailed as you can be.
                 This way I can ensure I am clued up on all the things I need to know before responding.
                 Please check out my about me page if you haven't already, because having the right photographer is super
                 important, and it's okay to not be for everyone.`}</Typography>
              <Typography variant={"body1"} color={"white"} paragraph align={'left'} className={"body-font"}>{
                `Once I've received your email, I'll be able to tell you about my availability,
                 and we can even set up a call via phone or video, whichever you prefer.
                 I can't wait to hear from you, let's make some magic happen!`}</Typography>
              <img src={Signature} alt={''} style={{maxWidth: '200px', margin: 0}}/>
            </Stack>
          </Fade>
        </Container>
        <Fade in={true} style={{
          transitionDuration: calculateTransitionTime([TransitionType.SLOW]),
          transitionDelay: calculateTransitionTime([TransitionType.SLOW])
        }}>
          <Box sx={{
            pb: smallScreen ? 1 : 4,
            pt: smallScreen ? 1 : 0,
            backgroundImage: `url(${Background})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundAttachment: smallScreen ? 'local' : 'fixed'
          }}>
            <Container maxWidth="md" sx={{pt: smallScreen ? 2 : 10}}>
              <Paper sx={{textAlign: "center", p: 4, mb: 4}}>
                <form className="contact=form" onSubmit={handleSubmit(submit)}>
                  <Stack spacing={4}>
                    <ToggleButtonGroup
                        fullWidth
                        exclusive
                        value={formType}
                        onChange={(event: React.MouseEvent<HTMLElement>,
                                   newType: FormType | null,
                        ) => {
                          if (newType) {
                            setFormType(newType);
                            setSubmitted(false);
                          }
                        }}>
                      <ToggleButton value={FormType.WEDDING}>Weddings</ToggleButton>
                      <ToggleButton value={FormType.LIFESTYLE}>Lifestyle</ToggleButton>
                    </ToggleButtonGroup>
                    <Collapse in={!!formType}>
                      {formType === FormType.WEDDING &&
                          <WeddingForm control={control}/>}
                      {formType === FormType.LIFESTYLE &&
                          <LifestyleForm control={control}/>}
                      {!!formType &&
                          <Button color="inherit" type={"submit"} variant={"outlined"} sx={{mt: 4, borderRadius: 0}}
                                  fullWidth>Submit</Button>}
                      <Dialog open={submitted} onClose={() => navigateToLink("/")}>
                        <DialogTitle>Submitted Successfully!</DialogTitle>
                        <DialogActions>
                          <Button onClick={() => navigateToLink("/")} autoFocus>Close</Button>
                        </DialogActions>
                      </Dialog>
                    </Collapse>
                  </Stack>
                </form>
              </Paper>
            </Container>
          </Box>
        </Fade>
        <FooterDesktop setNavigating={setNavigating}/>
      </div>
    </Fade>

  </>
}

export default Contact;