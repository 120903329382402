import React from "react";
import {AppBar, Box, Button, Dialog, IconButton, Slide, Stack, useScrollTrigger} from "@mui/material";
import {Squash as Hamburger} from 'hamburger-react'
import {calculateTransitionTime, TransitionType} from "../../../constants/transition-constants";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import {useNavigate} from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2";
import {delay} from "../../functions/delay";
import {NavBarProps} from "../nav-bar-props";
import {navigateToFacebook, navigateToInstagram} from "../../functions/new-tab-links";
import BackgroundGraphic from "../../../assets/svg/Traddy-rose-v2.svg";

const NavBarMobile = (props: NavBarProps) => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = React.useState(false);
  const scrollTrigger = useScrollTrigger();

  const openMenu = () => {
    setShowMenu(true);
  };

  const closeMenu = () => {
    setShowMenu(false);
  }

  const hamburger = () => {
    return <Hamburger size={20} toggled={showMenu}/>
  }

  async function navigateToLink(to: string) {
    closeMenu();
    await delay(700);
    navigate(to)
  }

  const navBar = <AppBar sx={{background: "none", boxShadow: "none", p: 2}} position={"fixed"}>
    <Grid2 container spacing={2}>
      <Grid2 xs={6} textAlign={"left"}>
        <IconButton
            color="inherit" onClick={openMenu}
            aria-label="displays navigation menu"
            aria-controls="menu-appbar"
            aria-haspopup="true" sx={{p: 0}}>
          {hamburger()}
        </IconButton>
      </Grid2>
      <Grid2 xs={6} textAlign={"right"} sx={{alignItems: 'center'}}>
        <IconButton color="inherit" onClick={navigateToInstagram} size={"large"}>
          <InstagramIcon/>
        </IconButton>
        <IconButton color="inherit" onClick={navigateToFacebook} size={"large"}>
          <FacebookIcon/>
        </IconButton>
      </Grid2>
    </Grid2>
    <Dialog open={showMenu} fullScreen transitionDuration={500}
            TransitionProps={{timeout: 700, style: {transitionDelay: "200ms"}}} hideBackdrop
            PaperProps={{style: {backgroundColor: "black"}}}>
      <AppBar sx={{background: "none", boxShadow: "none", p: 2}}>
        <Grid2 container spacing={2}>
          <Grid2 xs={12} textAlign={"left"}>
            <IconButton
                color="inherit" onClick={closeMenu}
                aria-label="hides navigation menu"
                aria-controls="menu-appbar"
                aria-haspopup="false" sx={{p: 0}}>
              {hamburger()}
            </IconButton>
          </Grid2>
        </Grid2>
      </AppBar>
      <Box sx={{
        display: 'flex',
        minHeight: "100vh",
        alignItems: 'center',
        justifyContent: 'center',
        position: "relative",
      }}>
        <img alt={""} src={BackgroundGraphic}
             style={{
               position: "absolute",
               height: "100%",
               width: "100%",
               objectFit: "cover",
               objectPosition: "center top",
               opacity: "20%",
             }}/>
        <Stack spacing={2}>
          <Button color="inherit" size={"large"} onClick={() => navigateToLink("/")}>Home</Button>
          <Button color="inherit" size={"large"} onClick={() => navigateToLink("/about")}>About</Button>
          <Button color="inherit" size={"large"} onClick={() => navigateToLink("/portfolio")}>Portfolio</Button>
          <Button color="inherit" size={"large"} onClick={() => navigateToLink("/pricing")}>Pricing</Button>
          <Button color="inherit" size={"large"} onClick={() => navigateToLink("/contact")}>Contact</Button>
        </Stack>
      </Box>
    </Dialog>
  </AppBar>;

  return <Slide direction={"down"} timeout={500} in={!scrollTrigger} style={{
    transitionDuration: calculateTransitionTime([TransitionType.FAST])
  }}>
    {navBar}
  </Slide>
}

export default NavBarMobile;