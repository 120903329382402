import MainPackageGraphic from "../../assets/images/investment/graphics/party popper.png";
import MainPackagePhoto from "../../assets/images/investment/photos/main-package.webp";
import SecondaryPackageGraphic from "../../assets/images/investment/graphics/lightning.png";
import SecondaryPackagePhoto from "../../assets/images/investment/photos/secondary-package.webp"
import SmallPackageGraphic from "../../assets/images/investment/graphics/flowers.png"
import SmallPackagePhoto from "../../assets/images/investment/photos/small-package.webp";
import ExtrasSessionGraphic from "../../assets/images/investment/graphics/leaf branch.png";
import SecondShooterGraphic from "../../assets/images/investment/graphics/camera.png";
import LifestyleGraphic1 from "../../assets/images/investment/graphics/sprig.png";
import LifestylePhoto1 from "../../assets/images/investment/photos/lifestyle-1.webp";
import {InvestmentPackageProps} from "./investment-package/investment-package";
import {BackgroundType} from "./background-type";

export const INVESTMENT_DATA = {

  mainPackage: {
    title: "THE FULL WORKS",
    description: "Coverage from prep to party - nothing gets missed with this bad boy! You get me from first thing in the morning, until shapes are thrown on the dancefloor.",
    text: [
      "A Complementary Lovers Shoot - the perfect ice breaker. Get a feel for what it's like to be in front of the camera!",
      "A private online gallery with all your images",
      "Print Package - This will include 15 6\"x4\" premium prints, plus a USB and some goodies from me as a thank you.",
      "Sneak Peeks within 48 hours"
    ],
    price: "£1900",
    graphic: MainPackageGraphic,
    image: MainPackagePhoto,
    backgroundType: BackgroundType.YELLOW
  } as InvestmentPackageProps,
  mediumPackage: {
    title: "THE SMALLER BUT MIGHTY",
    description: "Coverage from ceremony to first dance - want full day coverage but without all the extras? Gotcha, I'm on it.",
    text: [
      "A private online gallery with all your images",
      "Print Package - This will include 10 6\"x4\" premium prints, plus a USB and some goodies from me as a thank you.",
      "Sneak Peeks within 48 hours"
    ],
    price: "£1600",
    graphic: SecondaryPackageGraphic,
    image: SecondaryPackagePhoto,
    backgroundType: BackgroundType.GREY
  } as InvestmentPackageProps,
  smallPackage: {
    title: "THE SIMPLY WED",
    description: "Coverage for a ceremony and a smaller get together - for those smaller, more intimate weddings with a whole lotta love. Not everyone wants to do things in the traditional way, and I'm here for it!",
    text: [
      "A private online gallery with all your images",
      "Print Package - This will include 5 6\"x4\" premium prints, plus a USB and some goodies from me as a thank you.",
      "Sneak Peeks within 48 hours"
    ],
    price: "£1100",
    graphic: SmallPackageGraphic,
    image: SmallPackagePhoto,
    backgroundType: BackgroundType.YELLOW
  } as InvestmentPackageProps,
  extraCoverage: {
    title: "ADDITIONAL COVERAGE",
    text: [
      "Feel like you need just a little bit more time? This is the extra for you! If this is something that interests you, then give me a shout and we'll get it sorted."
    ],
    price: "£250/h",
    graphic: ExtrasSessionGraphic,
    backgroundType: BackgroundType.GREY,
    removeIcons: true
  } as InvestmentPackageProps,
  secondShooter: {
    title: "SECOND SHOOTER",
    text: [
      `Want some extra angles? Or are you both getting ready in different locations? If so, this is a great option to make sure nothing gets missed.
         Your entire day documented exactly as it happens. I'm only one gal after all!`,
      "I have a fantastic selection of people I trust completely for this, so your day will be in the best hands imaginable."
    ],
    price: "£350",
    graphic: SecondShooterGraphic,
    backgroundType: BackgroundType.GREY,
    topDivider: true,
    removeIcons: true
  } as InvestmentPackageProps,
  lifestylePackage: {
    title: "LIFESTYLE",
    description: "Weddings aren't the only thing I shoot, ya know!",
    text: [
      "This covers all those special moments, like engagements, family, and maternity.",
      "A private online gallery with all your images",
    ],
    price: "£250 per hour",
    graphic: LifestyleGraphic1,
    image: LifestylePhoto1,
    backgroundType: BackgroundType.YELLOW
  } as InvestmentPackageProps
}