import React from "react";
import {Box, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import Carousel from "./carousel/carousel";

const HomeTestimonials = () => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const mediumScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return <Box
      sx={{width: "100vw", maxWidth: "100%", pt: smallScreen ? 2 : 8, backgroundColor: "rgb(248,203,156)"}}>
    <Stack spacing={smallScreen ? 2 : 8} alignItems={"center"} justifyContent={"center"}>
      {smallScreen &&
          <Stack alignItems={"center"} justifyContent={"center"} spacing={-5} sx={{transform: "translateY(1vh)"}}>
            <Typography variant={'h6'} paragraph
                        className={"header-font"}
                        letterSpacing={6}
                        fontWeight={"bold"}
                        sx={{mb: 0, zIndex: 2}}>SHARING THE</Typography>
            <Typography variant={"h2"}
                        className={"scripted-font-alternate"}
                        sx={{color: 'rgb(187,57,60)', transform: "rotate(-5deg)"}}>Love</Typography>
          </Stack>}
      {mediumScreen &&
          <Stack alignItems={"center"} justifyContent={"center"} spacing={-8} sx={{transform: "translateY(2vh)"}}>
            <Typography variant={'h4'} paragraph
                        className={"header-font"}
                        letterSpacing={6}
                        fontWeight={"bold"}
                        sx={{mb: 0, zIndex: 2}}>SHARING THE</Typography>
            <Typography variant={"h1"}
                        className={"scripted-font-alternate"}
                        sx={{color: 'rgb(187,57,60)', transform: "rotate(-5deg)"}}>Love</Typography>
          </Stack>}
      {largeScreen &&
          <Stack alignItems={"center"} justifyContent={"center"} spacing={-10} sx={{transform: "translateY(2vh)"}}>
            <Typography variant={'h3'} paragraph
                        className={"header-font"}
                        letterSpacing={6}
                        fontWeight={"bold"}
                        sx={{mb: 0, zIndex: 2}}>SHARING THE</Typography>
            <Typography variant={"h1"}
                        className={"scripted-font-alternate"}
                        sx={{
                          color: 'rgb(187,57,60)',
                          transform: "rotate(-5deg)",
                          fontSize: "150px"
                        }}>Love</Typography>
          </Stack>}

      <Box sx={{
        width: "100%",
        backgroundColor: "rgb(30,30,30)",
      }}>
        <Carousel/>
      </Box>
    </Stack>
  </Box>
}

export default HomeTestimonials;