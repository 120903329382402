import React from "react";
import {Stack, TextField} from "@mui/material";
import {Controller} from "react-hook-form";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

interface LifestyleFormProps {
  control: any;
}

const LifestyleForm = (props: LifestyleFormProps) => {
  const {control} = props
  return <Stack spacing={4}>
    <Controller control={control}
                name="from_name"
                rules={{required: true}}
                render={({field}) =>
                    <TextField {...field} variant="outlined" label="Your Name" required/>
                }/>
    <Controller control={control}
                name="pronouns"
                rules={{required: true}}
                render={({field}) =>
                    <TextField {...field} variant="outlined" label="Your Preferred Pronouns" required/>
                }/>
    <Controller control={control}
                name="other_names"
                render={({field}) =>
                    <TextField {...field} variant="outlined" label="Names of others involved"
                               helperText={"Please add names, pronouns, and their roles here if applicable"} multiline/>
                }/>
    <Controller control={control}
                name="from_email"
                rules={{required: true}}
                render={({field}) =>
                    <TextField {...field} variant="outlined" label="Email" fullWidth required/>
                }/>
    <Controller control={control}
                name="mobile"
                rules={{required: true}}
                render={({field}) =>
                    <TextField {...field} variant="outlined" label="Mobile Number" fullWidth required/>
                }/>
    <Controller control={control}
                name={"date"}
                rules={{required: true}}
                defaultValue={null}
                render={({field}) =>
                    <DatePicker value={field.value} inputFormat={"DD/MM/yyyy"} minDate={moment()}
                                onChange={(value) => field.onChange(value)}
                                renderInput={(params) => <TextField {...params} label={"Date"} required defaultValue={null} />} disablePast/>
                }/>
    <Controller control={control}
                name="venue"
                rules={{required: true}}
                render={({field}) =>
                    <TextField {...field} variant="outlined" label="Location" fullWidth required/>
                }/>
    <Controller control={control}
                name="type_of_shoot"
                rules={{required: true}}
                render={({field}) =>
                    <TextField {...field} variant="outlined" label="Shoot type" fullWidth required
                               helperText={"e.g. Family, Couple, Engagement, Party"}/>
                }/>
    <Controller control={control}
                name="message"
                rules={{required: true}}
                render={({field}) =>
                    <TextField {...field} variant="outlined" label="Tell me all about it!" fullWidth required multiline
                               rows={6}/>
                }/>
    <Controller control={control}
                name="source"
                rules={{required: true}}
                render={({field}) =>
                    <TextField {...field} variant="outlined" label="Where did you hear about me?" fullWidth required/>
                }/>
    <Controller control={control}
                name="insta"
                render={({field}) =>
                    <TextField {...field} variant="outlined" label="Do you have Instagram?" helperText={"If so please add your username here!"} fullWidth/>
                }/>
  </Stack>
}

export default LifestyleForm;