import React from "react";
import {FormControl, InputLabel, MenuItem, Select, Stack, TextField} from "@mui/material";
import {Controller} from "react-hook-form";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

interface WeddingFormProps {
  control: any;
}

const WeddingForm = (props: WeddingFormProps) => {
  const {control} = props
  return <Stack spacing={4}>
    <Controller control={control}
                name="partner_1"
                rules={{required: true}}
                render={({field}) =>
                    <TextField {...field} variant="outlined" label="Your Name" required className={"body-font"}
                               color={"error"}/>
                }/>
    <Controller control={control}
                name="partner_1_pronouns"
                rules={{required: true}}
                render={({field}) =>
                    <TextField {...field} variant="outlined" label="Your Preferred Pronouns" required color={"error"}/>
                }/>
    <Controller control={control}
                name="partner_2"
                rules={{required: true}}
                render={({field}) =>
                    <TextField {...field} variant="outlined" label="Partner's Name" fullWidth required color={"error"}/>
                }/>
    <Controller control={control}
                name="partner_2_pronouns"
                rules={{required: true}}
                render={({field}) =>
                    <TextField {...field} variant="outlined" label="Partner's Preferred Pronouns" required
                               color={"error"}/>
                }/>
    <Controller control={control}
                name="from_email"
                rules={{required: true}}
                render={({field}) =>
                    <TextField {...field} variant="outlined" label="Email" fullWidth required color={"error"}/>
                }/>
    <Controller control={control}
                name="mobile"
                rules={{required: true}}
                render={({field}) =>
                    <TextField {...field} variant="outlined" label="Mobile Number" fullWidth required color={"error"}/>
                }/>
    <Controller control={control}
                name={"date"}
                rules={{required: true}}
                defaultValue={null}
                render={({field}) =>
                    <DatePicker value={field.value} inputFormat={"DD/MM/yyyy"} minDate={moment()}
                                onChange={(value) => field.onChange(value)}
                                renderInput={(params) => <TextField {...params} color={"error"} label={"Date"} required
                                                                    defaultValue={null}/>} disablePast/>
                }/>
    <Controller control={control}
                name="venue"
                rules={{required: true}}
                render={({field}) =>
                    <TextField {...field} variant="outlined" label="Venue" fullWidth required color={"error"}/>
                }/>
    <Controller control={control}
                name="stage_of_planning"
                rules={{required: true}}
                render={({field}) =>
                    <TextField {...field} variant="outlined" label="Where are you at with planning?" fullWidth required
                               multiline color={"error"}
                               rows={2} helperText={"e.g. Ready to Book, Just browsing"}/>
                }/>
    <FormControl required fullWidth>
      <InputLabel id="package-selector-label" color={"error"}>Package of Interest</InputLabel>
      <Controller control={control}
                  name="package"
                  rules={{required: true}}
                  render={({field}) =>
                      <Select {...field} color={"error"}
                              labelId="package-selector-label"
                              label="Package of Interest">
                        <MenuItem value={"The Full Works"}>The Full Works</MenuItem>
                        <MenuItem value={"The Smaller But Mighty"}>The Smaller But Mighty</MenuItem>
                        <MenuItem value={"The Simply Wed"}>The Simply Wed</MenuItem>
                        <MenuItem value={"Bespoke"}>Bespoke</MenuItem>
                      </Select>
                  }/>
    </FormControl>
    <Controller control={control}
                name="message"
                rules={{required: true}}
                render={({field}) =>
                    <TextField {...field} variant="outlined" label="Tell me all about it!" fullWidth required multiline
                               rows={6} color={"error"}/>
                }/>
    <Controller control={control}
                name="questions"
                render={({field}) =>
                    <TextField {...field} variant="outlined" label="Any Questions?" fullWidth multiline
                               rows={6} color={"error"}/>
                }/>
    <Controller control={control}
                name="source"
                rules={{required: true}}
                render={({field}) =>
                    <TextField {...field} variant="outlined" label="Where did you hear about me?" fullWidth required
                               color={"error"}/>
                }/>
    <Controller control={control}
                name="insta"
                render={({field}) =>
                    <TextField {...field} variant="outlined" label="Do you have Instagram?"
                               helperText={"If so please add your username here!"} fullWidth color={"error"}/>
                }/>
  </Stack>
}

export default WeddingForm;