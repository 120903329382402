import React from "react";

import {Container, Fade, ImageList, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import HeartArrowIcon from "../../assets/images/portfolio/icon.png"
import {calculateTransitionTime, TransitionType} from "../../constants/transition-constants";
import LoadingImageListItem from "../../shared/loading-image/loading-image-list-item";
import {useTitle} from "../../hooks/use-title";
import FooterDesktop from "../../shared/footer/footer-desktop";
import WEDDING_IMAGES from "../../assets/images/portfolio";

interface PortfolioProps {
  navigating: boolean;
  setNavigating: any;
}

const Portfolio = (props: PortfolioProps) => {
  useTitle('Portfolio | UK Alternative Wedding Photographer | Midlands-Based Wedding Photography')
  const {navigating, setNavigating} = props;
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return <>
    <Fade in={!navigating} timeout={500} appear={false}>
      <div>
        <Container maxWidth="sm" sx={{pt: smallScreen ? 12 : 16}}>
          <Fade in={true} style={{
            transitionDuration: calculateTransitionTime([TransitionType.SLOW])
          }}>
            <Stack sx={{width: "100%", mb: smallScreen ? 3 : 5}} alignItems={"center"} spacing={2}>
              <Typography variant={"h6"} color={"white"} paragraph className={"header-font"}
                          letterSpacing={6}
                          fontWeight={"bold"}>{
                `THE PROOF IS IN THE PUDDIN'`}</Typography>
              <img src={HeartArrowIcon} alt={''} style={{maxWidth: '100px', margin: 0}}/>
            </Stack>
          </Fade>
        </Container>

        <Fade in={true} style={{
          transitionDuration: calculateTransitionTime([TransitionType.SLOW]),
          transitionDelay: calculateTransitionTime([TransitionType.SLOW])
        }}>
          <div>
            <Container maxWidth={"xl"}>
              {/*TODO replace with <Masonry> component https://mui.com/material-ui/react-masonry/*/}
              <ImageList variant={"masonry"} cols={smallScreen ? 2 : 4} gap={4}>
                {WEDDING_IMAGES.map(item =>
                    <LoadingImageListItem src={item.image} transition={calculateTransitionTime([TransitionType.SLOW])}
                                          fourByThree={item.fourByThree} key={`${item.image}`}/>)}
              </ImageList>
            </Container>
            <FooterDesktop setNavigating={setNavigating}/>
          </div>
        </Fade>
      </div>
    </Fade>
  </>
}

export default Portfolio;