interface TransitionConstants {
  fastTransition: number,
  slowTransition: number,
  noTransition: number,
  transitionSuffix: string
}

export enum TransitionType {
  FAST = "FAST",
  SLOW = "SLOW"
}

const transitionConstants: TransitionConstants = {
  fastTransition: 500,
  slowTransition: 1000,
  noTransition: 0,
  transitionSuffix: 'ms'
}

export function calculateTransitionTime(types?: TransitionType[], navBarOpen?: boolean): string {
  const {fastTransition, slowTransition, noTransition, transitionSuffix} = transitionConstants

  if (!types || types.length <= 0) {
    return noTransition + transitionSuffix;
  }

  if (navBarOpen && types.length > 0) {
    types.pop();
  }

  if (types.length <= 0) {
    return noTransition + transitionSuffix;
  }
  return types
      .map(type => {
        switch (type) {
          case TransitionType.FAST: return fastTransition;
          case TransitionType.SLOW: return slowTransition;
          default: return noTransition;
        }
      })
      .reduce((prev, curr) => prev + curr) + transitionSuffix;
}

export function createTransitionArray(type: TransitionType, count: number, extraTypes?: TransitionType[]): TransitionType[] {
  const array = new Array(count).fill(null).map(count => type);
  if (extraTypes && extraTypes.length > 0) {
    array.push(...extraTypes);
  }
  return array;
}