import React from "react";
import {AppBar, Button, IconButton, Slide, Stack, useMediaQuery, useScrollTrigger, useTheme} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import {useNavigate} from "react-router-dom";
import {calculateTransitionTime, TransitionType} from "../../../constants/transition-constants";
import Logo from "../../../assets/images/logos/logo-small/logo-white-md.png";
import {delay} from "../../functions/delay";
import {NavBarProps} from "../nav-bar-props";
import {navigateToFacebook, navigateToInstagram} from "../../functions/new-tab-links";

const NavBarDesktop = (props: NavBarProps) => {
  const {setNavigating} = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const scrollTrigger = useScrollTrigger();

  async function navigateToLink(to: string) {
    setNavigating(true)
    await delay(500);
    navigate(to)
    setNavigating(false)
  }

  const navBar = <AppBar sx={{background: "rgb(30,30,30)", boxShadow: "none", p: 1}} position={"fixed"}>
    <Grid2 container spacing={2} alignItems={"center"}>
      {!mediumScreen && <Grid2 xs={2} alignItems={"center"} justifyContent={"center"}>
        <img src={Logo} alt={''} style={{
          maxHeight: "32px"
        }}/>
      </Grid2>}
      <Grid2 xs={mediumScreen ? 12 : 8}>
        <Stack direction={"row"} spacing={2} justifyContent={"center"}>
          <Button color="inherit" size={"large"}
                  onClick={() => navigateToLink("/")}>Home</Button>
          <Button color="inherit" size={"large"}
                  onClick={() => navigateToLink("/about")}>About</Button>
          <Button color="inherit" size={"large"}
                  onClick={() => navigateToLink("/portfolio")}>Portfolio</Button>
          <Button color="inherit" size={"large"}
                  onClick={() => navigateToLink("/pricing")}>Pricing</Button>
          <Button color="inherit" size={"large"}
                  onClick={() => navigateToLink("/contact")}>Contact</Button>
        </Stack>
      </Grid2>
      {!mediumScreen && <Grid2 xs={2}>
        <IconButton color="inherit" size={"large"} onClick={navigateToInstagram}>
          <InstagramIcon/>
        </IconButton>
        <IconButton color="inherit" size={"large"} onClick={navigateToFacebook}>
          <FacebookIcon/>
        </IconButton>
      </Grid2>}
    </Grid2>
  </AppBar>

  return <Slide direction={"down"} timeout={500} in={!scrollTrigger} style={{
    transitionDuration: calculateTransitionTime([TransitionType.FAST])
  }}>
    {navBar}
  </Slide>

}

export default NavBarDesktop;