import React, {useState} from 'react';
import './App.css';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import Home from "./pages/home/home";
import Contact from "./pages/contact/contact";
import Portfolio from "./pages/portfolio/portfolio";
import {createTheme, ThemeProvider} from "@mui/material";
import About from "./pages/about/about";
import ScrollToTop from "./hooks/scroll-to-top";
import Investment from "./pages/investment/investment";
import NavBar from "./shared/nav-bar/nav-bar";

const mainTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: 'rgb(30, 30, 30)',
      contrastText: '#f5f5f5'
    },
    secondary: {
      main: 'rgb(248,203,156)',
      contrastText: 'rgb(30, 30, 30)'
    },
    info: {
      main: 'rgb(187,57,60)'
    },
    warning: {
      main: 'rgb(228, 162, 91)'
    },
    error: {
      main: 'rgb(255, 255, 255)'
    }
  },
  typography: {
    fontFamily: "'Body', 'Roboto', sans-serif",
    fontSize: 16
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 920,
      lg: 1200,
      xl: 1536,
    },
  },
})

function App() {
  const [navigating, setNavigating] = useState<boolean>(false);
  return (
      <div className="App">
        <BrowserRouter>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"en"}>
            <ThemeProvider theme={mainTheme}>
              <ScrollToTop>
                <NavBar setNavigating={setNavigating}/>
                <Routes>
                  <Route path="/" element={<Home navigating={navigating} setNavigating={setNavigating}/>}/>
                  <Route path="/about" element={<About navigating={navigating} setNavigating={setNavigating}/>}/>
                  <Route path="/contact" element={<Contact navigating={navigating} setNavigating={setNavigating}/>}/>
                  <Route path="/portfolio" element={<Portfolio navigating={navigating}
                                                               setNavigating={setNavigating}/>}/>
                  <Route path="/pricing" element={<Investment navigating={navigating} setNavigating={setNavigating}/>}/>
                  <Route path="/*" element={<Navigate replace to="/"/>}/>
                </Routes>
              </ScrollToTop>
            </ThemeProvider>
          </LocalizationProvider>
        </BrowserRouter>
      </div>
  );
}

export default App;
