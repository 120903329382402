import React from "react";
import {
  Box,
  Container,
  Divider,
  Fade,
  Grid,
  Link,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import FooterDesktop from "../../shared/footer/footer-desktop";
import HeaderGraphic from "../../assets/images/investment/graphics/MD logo v2_arrow.svg"
import PrintPackagePhoto1 from "../../assets/images/investment/photos/226A7264.webp"
import PrintPackagePhoto2 from "../../assets/images/investment/photos/226A7267.webp"
import PrintPackagePhoto3 from "../../assets/images/investment/photos/226A7275.webp"
import {calculateTransitionTime, TransitionType} from "../../constants/transition-constants";
import {useNavigate} from "react-router-dom";
import InvestmentPackage from "./investment-package/investment-package";
import {INVESTMENT_DATA} from "./investment-data";
import Background from "../../assets/images/investment/background-1.webp";
import {delay} from "../../shared/functions/delay";
import StyledTabButtonColoured from "../../shared/button/styled-tab-button/styled-tab-button-coloured";

interface InvestmentProps {
  navigating: boolean;
  setNavigating: any;
}

interface WeddingInvestmentProps {
  setNavigating: any;
}


const WeddingInvestment = (props: WeddingInvestmentProps) => {
  const {setNavigating} = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const mediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const mediumOrSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  async function navigateToLink(to: string) {
    setNavigating(true)
    await delay(500);
    navigate(to)
    setNavigating(false)
  }

  return <>
    <InvestmentPackage {...INVESTMENT_DATA.mainPackage} />
    <InvestmentPackage {...INVESTMENT_DATA.mediumPackage} />
    <InvestmentPackage {...INVESTMENT_DATA.smallPackage} />
    <div style={{
      backgroundImage: `url(${Background})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundAttachment: smallScreen ? 'local' : "fixed",
      minHeight: smallScreen ? "60vh" : "80vh",
      width: "100vw",
      maxWidth: "100%"
    }}>
      <Paper sx={{
        background: "rgba(0, 0, 0, 0.3)",
        minHeight: smallScreen ? "60vh" : "80vh",
        Width: "100vw"
      }}>
        <Box sx={{
          display: 'flex',
          minHeight: smallScreen ? "60vh" : "80vh",
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Stack spacing={smallScreen ? 4 : 8} sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textTransform: 'uppercase'
          }}>
            <Typography variant={smallScreen ? 'body1' : mediumScreen ? 'h4' : 'h3'} color={"white"} paragraph
                        className={"secondary-font-shadow"} fontWeight={smallScreen ? "bold" : "lighter"}
                        letterSpacing={smallScreen ? 4 : 6}
                        sx={{mb: 0}}>{`Got something else in mind?`}</Typography>
            <StyledTabButtonColoured onClick={() => navigateToLink("/contact")} text={"Let's Talk Bespoke"}/>
          </Stack>
        </Box>
      </Paper>
    </div>
    <Typography variant={"h6"} color={"white"} className={"header-font"}
                letterSpacing={6} sx={{pt: smallScreen ? 6 : 8}}
                fontWeight={"bold"}>{
      `EXTRAS`}</Typography>
    <InvestmentPackage {...INVESTMENT_DATA.extraCoverage} />
    <InvestmentPackage {...INVESTMENT_DATA.secondShooter} />
    <Box sx={{
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }}>
      <Container maxWidth={"sm"} sx={{py: smallScreen ? 6 : 8}}>
        <Divider variant={"middle"} color={"white"} sx={{mt: smallScreen ? -6 : -8, mb: smallScreen ? 6 : 8}}/>
        <Grid container sx={{alignItems: "baseline"}} alignItems={"center"}
              spacing={4}>
          <Grid item xs={12}>
            <Typography variant={"h6"} color={"white"} className={"header-font"}
                        letterSpacing={6}
                        fontWeight={"bold"}
                        sx={{pb: smallScreen ? 2 : 4}}>{
              `PRINT PACKAGE`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={smallScreen ? 2 : 4} direction={"row"} alignItems={"center"} justifyContent={"center"}>
              <img src={PrintPackagePhoto1} alt={""}
                   style={{maxWidth: smallScreen ? "48%" : mediumOrSmallScreen ? "40%" : "50%"}}/>
              <img src={PrintPackagePhoto3} alt={""}
                   style={{maxWidth: smallScreen ? "48%" : mediumOrSmallScreen ? "40%" : "50%"}}/>
              {!smallScreen &&
                  <img src={PrintPackagePhoto2} alt={""} style={{maxWidth: mediumOrSmallScreen ? "40%" : "50%"}}/>}
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Typography variant={"body1"}
                        color={"white"}
                        paragraph textAlign={"left"}
                        sx={{pt: smallScreen ? 2 : 4}} className={"body-font"}>
              {`Here's a quick glimpse of my print packages. Inside contains a branded pouch with your prints and your USB safely tucked away.
                  Feel free to let me know if there are any must haves in your gallery that you want including,
                  or you can leave it up to me and I can send you some of my highlights as a surprise!`}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
    <InvestmentPackage {...INVESTMENT_DATA.lifestylePackage} />
  </>
}

const Investment = (props: InvestmentProps) => {
  const {navigating, setNavigating} = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const scrollIntoView = () => document.getElementById("LIFESTYLE-container")?.scrollIntoView({
    behavior: "smooth"
  })

  async function navigateToLink(to: string) {
    setNavigating(true)
    await delay(500);
    navigate(to)
    setNavigating(false)
  }

  return <>
    <Fade in={!navigating} timeout={500} appear={false}>
      <div>
        <Container maxWidth={"sm"} sx={{pt: smallScreen ? 12 : 16}}>
          <Fade in={true} style={{
            transitionDuration: calculateTransitionTime([TransitionType.SLOW])
          }}>
            <Stack sx={{width: "100%", pb: smallScreen ? 6 : 8}} alignItems={"center"}
                   spacing={4}>
              <Typography variant={"h6"} color={"white"} paragraph className={"header-font"}
                          letterSpacing={6}
                          fontWeight={"bold"}>SO THE <span style={{color: 'rgb(187,57,60)'}}>BIG</span> QUESTION IS...
                HOW MUCH?</Typography>
              <img src={HeaderGraphic} alt={''} style={{
                width: "150px", height: "100px",
                objectFit: "cover",
                objectPosition: "center center", margin: 0
              }}/>
              <Typography variant={"body1"} color={"white"} paragraph textAlign={"left"} sx={{width: "100%"}}
                          className={"body-font"}>{
                `The age old question, let's be honest. Don't worry babes, I got you.`}</Typography>
              <Typography variant={"body1"} color={"white"} paragraph textAlign={"left"} sx={{width: "100%"}}
                          className={"body-font"}>{
                `It is super important that y'all create a day that is totally your vibe. With this in mind, 
                I've created my packages to ensure my couples get the coverage that suits them best. 
                Let me tell your story the way it was supposed to be told.`}</Typography>
              <Typography variant={"body1"} color={"white"} paragraph textAlign={"left"} sx={{width: "100%"}}
                          className={"body-font"}>
                For more information about my lifestyle package, <Link
                  color={"inherit"} underline={"hover"} component={"button"} variant={"body1"} className={"body-font"}
                  sx={{mt: "-3px"}}
                  onClick={scrollIntoView}>click here</Link>.</Typography>
              <Typography variant={"body1"} color={"white"} paragraph textAlign={"left"} sx={{width: "100%"}}
                          className={"body-font"}>{
                `Please note, all bookings require a 25% deposit and a signed contract for the date to be secured.`}</Typography>
            </Stack>
          </Fade>
        </Container>
        <Fade in={true} style={{
          transitionDuration: calculateTransitionTime([TransitionType.SLOW]),
          transitionDelay: calculateTransitionTime([TransitionType.SLOW])
        }}>
          <div>
            <WeddingInvestment setNavigating={setNavigating}/>
            <Box sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
              <Container maxWidth={"sm"} sx={{pt: smallScreen ? 6 : 8}}>
                <Stack spacing={4} sx={{alignItems: "center", justifyContent: "center"}}>

                  <Typography variant={"h6"} color={"white"} paragraph className={"header-font"}
                              letterSpacing={6}
                              fontWeight={"bold"}>DOES ALL THIS SOUND GOOD TO YOU?</Typography>
                  <StyledTabButtonColoured onClick={() => navigateToLink("/contact")} text={"Let's Chat"}/>
                </Stack>
              </Container>
            </Box>
            <FooterDesktop setNavigating={setNavigating}/>
          </div>
        </Fade>
      </div>
    </Fade>
  </>
}

export default Investment;