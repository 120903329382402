import React from "react";
import {calculateTransitionTime, TransitionType} from "../../constants/transition-constants";
import {Box, Container, Fade, Grid, Paper, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import Profile from "../../assets/images/about/profile.webp"
import Profile2 from "../../assets/images/about/profile2.webp"
import {useNavigate} from "react-router-dom";
import FooterDesktop from "../../shared/footer/footer-desktop";
import {useTitle} from "../../hooks/use-title";
import QuoteBackground from "../../assets/images/about/chloe-and-graham.webp";
import {delay} from "../../shared/functions/delay";
import StyledTabButton from "../../shared/button/styled-tab-button/styled-tab-button";

interface AboutProps {
  navigating: boolean;
  setNavigating: any;
}

const About = (props: AboutProps) => {
  const {navigating, setNavigating} = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const mediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const mediumOrSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  useTitle('About Me | UK Alternative Wedding Photographer | Midlands-Based Wedding Photography');

  async function navigateToLink(to: string) {
    setNavigating(true)
    await delay(500);
    navigate(to)
    setNavigating(false)
  }

  return <>
    <Fade in={!navigating} timeout={500}>
      <div>
        <Container maxWidth={smallScreen ? "md" : "xl"} sx={{
          pt: smallScreen ? 12 : 16, pb: smallScreen ? 6 : 8
        }}>
          <Grid container columnSpacing={8} rowSpacing={smallScreen ? 4 : 12} justifyContent={"center"}>
            {mediumOrSmallScreen && <Grid item xs={12} alignItems={"center"}
                                          justifyContent={"center"}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%'
              }}>
                <img src={Profile} alt={""} style={{
                  maxWidth: "600px",
                  width: "40vw",
                  minWidth: "300px",
                  maxHeight: "600px",
                  height: "40vw",
                  minHeight: "300px",
                  objectFit: "cover",
                  objectPosition: "center center",
                  borderRadius: "50%"
                }}/>
              </Box>
            </Grid>}
            <Grid item xs={mediumOrSmallScreen ? 12 : 6} alignItems={"center"} justifyContent={"center"}>
              <Box sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <Container maxWidth={"sm"}>
                  <Stack sx={{width: "100%", mb: smallScreen ? 0 : 4}} alignItems={"center"}
                         spacing={smallScreen ? 2 : 4}>
                    <Typography variant={"h6"} color={"white"} paragraph className={"header-font"}
                                letterSpacing={6}
                                fontWeight={"bold"}>I'M <span
                        style={{color: 'rgb(187,57,60)', fontSize: "inherit"}}>MARILYN</span>.
                      AN EMO AT HEART, CAT LADY, MOTHER AND WIFE</Typography>
                    <Typography variant={"body1"} color={"white"} paragraph className={"body-font"}
                                textAlign={smallScreen ? "center" : "left"}>{
                      `I'm an alternative wedding photographer located in the Midlands, UK.
                         Don't get me wrong though, I'm always willing to travel, and being
                         in the centre of the country makes that a breeze.`}</Typography>
                    <Typography variant={"body1"} color={"white"} paragraph className={"body-font"}
                                textAlign={smallScreen ? "center" : "left"}>{
                      `I'm a vegan foodie who can't cook, and an unapologetic nerd. Y'all can find me in the Manga section in your local Forbidden Planet, with an exceptional snack in hand.
                         I've been labeled "the gin aunt" by my pals, and you know I'll be buying the rounds of sambuca on a night out!`}</Typography>
                    <Typography variant={"body1"} color={"white"} paragraph className={"body-font"}
                                textAlign={smallScreen ? "center" : "left"}>{
                      `I've always needed a creative outlet. After working as a model for many years, moving to photography has been such a natural progression for me.
                         If you're feeling anxious about posing or what to do, don't even worry, I have a mental rolodex of poses and tips up in my noggin to help you through the day.`}</Typography>
                    <Typography variant={"body1"} color={"white"} paragraph className={"body-font"}
                                textAlign={smallScreen ? "center" : "left"}>{
                      `I've been told that my cackle is infectious, and I don't take myself too seriously at all. If you want someone who will be there to laugh with you and hype you up, I'll be there! I'll happily be your new BFF.`}</Typography>
                  </Stack>
                </Container>
              </Box>
            </Grid>
            {!mediumOrSmallScreen && <Grid item xs={6} alignItems={"center"}
                                           justifyContent={"center"}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%'
              }}>
                <img src={Profile} alt={""} style={{
                  maxWidth: "600px",
                  width: "40vw",
                  minWidth: "300px",
                  ...(!mediumScreen && {
                    maxHeight: "600px",
                    height: "40vw",
                    minHeight: "300px"
                  }),
                  objectFit: "cover",
                  objectPosition: "center center",
                  borderRadius: "50%"
                }}/>
              </Box>
            </Grid>}
          </Grid>
        </Container>
        <Fade in={true} style={{
          transitionDuration: calculateTransitionTime([TransitionType.SLOW]),
          transitionDelay: calculateTransitionTime([TransitionType.SLOW])
        }}>
          <div style={{
            backgroundImage: `url(${QuoteBackground})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundAttachment: smallScreen ? "local" : "fixed",
            minHeight: smallScreen ? "60vh" : "80vh",
            width: "100vw",
            maxWidth: "100%",
            position: "relative"
          }}>
            <div style={{
              width: "100%",
              height: smallScreen ? "25px" : "50px",
              clipPath: smallScreen ? "polygon(0 0, 100% 60%, 100% 100%, 0% 100%)" : "polygon(0 0, 100% 80%, 100% 100%, 0% 100%)",
              backgroundColor: "rgba(248,203,156)",
              position: "absolute",
              bottom: "-10px"
            }}/>
            <div style={{
              width: "100%",
              height: smallScreen ? "25px" : "50px",
              clipPath: smallScreen ? "polygon(0 0, 100% 0, 100% 100%, 0% 40%)" : "polygon(0 0, 100% 0, 100% 100%, 0% 20%)",
              backgroundColor: "rgba(30,30,30)",
              position: "absolute",
              top: "-10px"
            }}/>
            <Paper sx={{
              background: "rgba(0, 0, 0, 0.3)",
              minHeight: smallScreen ? "60vh" : "80vh",
              Width: "100vw"
            }}>
              <Box sx={{
                display: 'flex',
                minHeight: smallScreen ? "60vh" : "80vh", alignItems: 'center',
                justifyContent: 'center'
              }}>
                <Container maxWidth={"xl"}>
                  <Stack spacing={smallScreen ? 4 : 8} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <Typography variant={smallScreen ? 'body1' : mediumScreen ? 'h4' : 'h3'} color={"white"} paragraph
                                className={"secondary-font-shadow"} fontWeight={"lighter"}
                                sx={{mb: 0}}>{`"I am so so grateful that we had Marilyn as our wedding photographer! Honestly I couldn't have asked for anyone better."`}</Typography>
                    <Typography variant={smallScreen ? 'body1' : mediumScreen ? 'h5' : 'h4'} color={"white"} paragraph
                                className={"secondary-font-shadow"} fontWeight={"lighter"}>
                      {`Chloe & Graham`}
                    </Typography>
                  </Stack>
                </Container>
              </Box>
            </Paper>
          </div>
        </Fade>
        <Box sx={{
          pt: smallScreen ? 6 : 8, pb: smallScreen ? 6 : 8, background: "rgba(248,203,156)",
        }}>
          <Container maxWidth={smallScreen ? "md" : "xl"}>
            <Grid container columnSpacing={{xs: 0, sm: 0, md: 4, lg: 4, xl: 8}} rowSpacing={smallScreen ? 4 : 12}
                  justifyContent={"center"}>
              <Grid item xs={mediumOrSmallScreen ? 12 : 6} alignItems={"center"} justifyContent={"center"}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  width: '100%'
                }}>
                  <img src={Profile2} alt={""} style={{
                    maxWidth: "600px",
                    width: "40vw",
                    minWidth: "300px",
                    maxHeight: "600px",
                    height: "40vw",
                    minHeight: "300px",
                    objectFit: "cover",
                    objectPosition: "center center",
                    borderRadius: "50%"
                  }}/>
                </Box>
              </Grid>
              <Grid item xs={mediumOrSmallScreen ? 12 : 6} alignItems={"center"} justifyContent={"center"}>
                <Box sx={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <Container maxWidth={"sm"}>
                    <Stack sx={{width: "100%"}} alignItems={"center"} spacing={smallScreen ? 2 : 4}>
                      <Typography variant={"h6"} color={"primary"} paragraph
                                  className={"header-font"}
                                  letterSpacing={6}
                                  fontWeight={"bold"}>{`WHAT SHE'S ABOUT`}</Typography>
                      <Typography variant={"body1"} color={"primary"} paragraph className={"body-font"}
                                  textAlign={smallScreen ? "center" : "left"}>{
                        `No two people are the same, so why should your important moments be?
                         I fully believe that everyone should do things the way they want to.
                         Who cares what anyone else thinks!
                         You want a small, intimate wedding? DO IT. A big extravaganza with all of the trimmings? DO IT.
                         No wedding will be any less special, and I love a couple who throw traditions out the window.`}</Typography>
                      <Typography variant={"body1"} color={"primary"} paragraph className={"body-font"}
                                  textAlign={smallScreen ? "center" : "left"}>{
                        `Love is for everyone, no matter who you are.
                        Authenticity is at the heart of my photography. I love seeing people as they come.
                        Whether you love the camera, or feel a little shy, it doesn't matter!
                        I'll still be able to bring out what makes you shine.`}</Typography>
                      <Typography variant={"body1"} color={"primary"} paragraph className={"body-font"}
                                  textAlign={smallScreen ? "center" : "left"}>{
                        `My photography is honest and romantic, but with my own alternative spin on things.
                        From the small gentle kisses to the wild party dancing, I'll be there feeling it all with you.
                        Heck, I've even been known to throw some shapes on the dancefloor too.
                        Wedding photography isn't just transactional for me, I put my soul into every booking from the moment you enquire.`}
                      </Typography>
                      <Typography variant={"body1"} color={"primary"} paragraph className={"body-font"}
                                  textAlign={smallScreen ? "center" : "left"}>{
                        `If you think I'm your sort of person, and love what you see, let's have a chat!
                        I'd love to hear from you.`}</Typography>
                      <div/>
                      <StyledTabButton onClick={() => navigateToLink("/contact")} text={"Let's Get that ball rolling"}/>
                    </Stack>
                  </Container>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <FooterDesktop setNavigating={setNavigating}/>
      </div>
    </Fade>
  </>
}

export default About;